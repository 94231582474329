enum TabsEnum {
    home,
    about,
    covid19,
    services,
    promotion,
    security,
    contact,
    complaints,
    login,
}

type TabType = {id: TabsEnum, textID: 'homeTab' | 'aboutTab' | 'covidTab' | 'servicesTab' | 'promotionTag' | 'securityTab' | 'contactTab' | 'complaintsTab' | 'loginTab'}[]

const Tabs: TabType = [
    {id: TabsEnum.home, textID: 'homeTab'},
    {id: TabsEnum.about, textID: 'aboutTab'},
    {id: TabsEnum.covid19, textID: 'covidTab'},
    {id: TabsEnum.services, textID: 'servicesTab'},
    {id: TabsEnum.promotion, textID: 'promotionTag'},
    {id: TabsEnum.security, textID: 'securityTab'},
    {id: TabsEnum.contact, textID: 'contactTab'},
    {id: TabsEnum.complaints, textID: 'complaintsTab'},
    {id: TabsEnum.login, textID: 'loginTab'}
]

export {Tabs, TabsEnum}