import React, { useState, useEffect } from 'react'
import './DonationForm.css'
import Button from '../../Components/Button'
import Spinner from '../../Components/Spinner'
import ErrorMessage from '../../Components/ErrorMessage'
import { cleanURL } from '../../Utils/PureFunctions'
import CustomRadio from '../../Components/CustomRadio'
import InputField from '../../Components/InputField'
import firebase from 'firebase'
import Card from '../../Cards/Card'
import AddressFinder from '../../Components/AddressFinder'
import CampaignPicture from '../../Components/CampaignPicture'
import TitleWithBack from '../../Components/TitleWithBack'

function DonationForm(props: {
    id: string,
    db: firebase.firestore.Firestore,
    back: () => void,
}) {
    const [progress, setProgress] = useState(0)
    const [campaign, setCampaign] = useState<firebase.firestore.DocumentData | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [submitted, setSubmitted] = useState(false)
    
    const [amount, setAmount] = useState<string | null>(null)
    const [frequency, setFrequency] = useState<string | null>(null)
    const [collectionDate, setCollectionDate] = useState<string | null>(null)
    const [giftAid, setGiftAid] = useState<string | null>(null)
    const [title, setTitle] = useState<string | null>(null)
    const [firstNames, setFirstNames] = useState<string | null>(null)
    const [lastNames, setLastNames] = useState<string | null>(null)
    const [email, setEmail] = useState<string | null>(null)
    const [phone, setPhone] = useState<string | null>(null)
    const [consents, setConsents] = useState<string[]>([])
    const [accountName, setAccountName] = useState<string | null>(null)
    const [sortCode, setSortCode] = useState<string | null>(null)
    const [accountNumber, setAccountNumber] = useState<string | null>(null)

    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const [town, setTown] = useState('')
    const [country, setCountry] = useState('')
    const [postcode, setPostcode] = useState('')

    useEffect(() => {
        let mounted = true
        props.db.collection('campaigns').doc(props.id).get().then(doc => {
            if (!mounted) return
            if (!doc.exists) {
                setError('This campaign does not exist.')
                return
            }
            if (!doc.data()) {
                setError('Campaign document data is falsy.')
                return
            }
            setCampaign(doc)
        }).catch(error => {
            setError(error.message)
            console.log({error})
        })
        return () => {mounted = false}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const resetForm = () => {
        props.back()
    }
    
    const handleCheckboxChange = (
        value: string,
        event: React.ChangeEvent<HTMLInputElement>,
        array: string[],
        setArray: (array: string[]) => void,
    ) => {
        if (event.target.checked) {
            setArray(array.concat(value))
        } else {
            setArray(array.filter(v => v !== value))
        }
    }

    const handleNext = () => {
        setProgress(progress + 1)
    }

    const handleSubmit = () => {
        props.db.collection('donations').add({
            campaign: props.id,
            fundraiser: firebase.auth().currentUser ? firebase.auth().currentUser!.email : null,
            amount,
            frequency,
            collectionDate,
            giftAid,
            title,
            firstNames,
            lastNames,
            email,
            phone,
            address1,
            address2,
            town,
            country,
            postcode,
            consents,
            accountName,
            sortCode,
            accountNumber,
        }).then(() => {
            setSubmitted(true)
        }).catch(error => {
            setError(error.message)
            console.log({error})
        })
    }

    if (error !== null) return (
        <div className="centered">
            <ErrorMessage message={error}/>
            <Button handler={cleanURL} delay={500} text="Go To Login"/>
        </div>
    )
    
    if (submitted) return (
        <div className="donationform">
            <h1>Confirmation</h1>
            <div className="solid">
                <p>Thank you for your donation!</p>
            </div>
            <div className="centered">
                <Button handler={resetForm} delay={500} text="Next Donation"/>
            </div>
        </div>
    )
    
    if (campaign === null || campaign.data() === undefined) return (<Spinner/>)

    return (
        <div className="donationform">
            <TitleWithBack onClick={props.back} text="Back"/>
            <CampaignPicture id={props.id}/>
            <h1 className="huge centered">{campaign.data().name}</h1>
            <p>{campaign.data().description}</p>
            
            <div className="medspace"/>

            <Card
                title="Authorisation"
                buttons={[]}
            >
                <p>In order to set up a Direct Debit you must be the account holder of a personal bank or building society account and the sole required signatory on the account.</p>
            </Card>

            {progress >= 1 ? (
                <Card
                    title="Your Payment"
                    buttons={[]}
                >
                    <h1 className="nopad">Amount</h1>
                    <CustomRadio
                        name="amount"
                        values={[
                            { name: '£5', value: '5'},
                            { name: '£10', value: '10'},
                            { name: '£15', value: '15'},
                            { name: '£20', value: '20'},
                        ]}
                        otherType="number"
                        onChange={setAmount}
                    />

                    <div className="medspace"/>

                    <h1 className="nopad">Frequency</h1>
                    <CustomRadio
                        name="frequency"
                        values={[
                            { name: 'Monthly', value: 'Monthly'},
                            { name: 'Quarterly', value: 'Quarterly'},
                            { name: 'Half Yearly', value: 'Half Yearly'},
                            { name: 'Annually', value: 'Annually'},
                        ]}
                        otherType={null}
                        onChange={setFrequency}
                    />

                    <div className="medspace"/>

                    <h1 className="nopad">Collection Date</h1>
                    <p>Please specify your preferred collection date. If the collection date selected falls within the next 16 days, the first payment may be collected the following month.</p>
                    <input
                        className="input"
                        type="date"
                        onChange={(event) => setCollectionDate(event.target.value)}
                    />

                    <div className="medspace"/>

                    <h1 className="nopad">Boost your donation by 25p of Gift Aid for every £1 you donate</h1>
                    <p>Gift Aid is reclaimed by the charity from the tax you pay for the current tax year. Your address is needed to identify you as a current taxpayer.</p>
                    <p>I confirm I am a UK taxpayer and understand that if I pay less Income and/or Capital Gains Tax in the current tax year than the amount of Gift Aid claimed on all my donations it is my responsibility to pay any difference.</p>
                    <CustomRadio
                        name="giftaid"
                        values={[
                            { name: 'Yes, I would like to add Gift Aid to my donation', value: 'Yes'},
                            { name: 'No, I am not a UK tax payer', value: 'No'},
                        ]}
                        otherType={null}
                        onChange={setGiftAid}
                    />
                    <div className="medspace"/>
                </Card>
            ) : null}

            {progress >= 2 ? (
                <Card
                    title="Your Details"
                    buttons={[]}
                >
                    <h1 className="nopad">Title</h1>
                    <CustomRadio
                        name="title"
                        values={[
                            { name: 'Mr', value: 'Mr'},
                            { name: 'Ms', value: 'Ms'},
                            { name: 'Mrs', value: 'Mrs'},
                        ]}
                        otherType="text"
                        onChange={setTitle}
                    />
                    
                    <div className="medspace"/>

                    <table className="nopad">
                        <tbody>
                            <tr>
                                <td><h1 className="nopad">First Name(s)</h1></td>
                                <td>
                                    <InputField
                                        type="custom"
                                        onChange={setFirstNames}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td><h1 className="nopad">Last Name(s)</h1></td>
                                <td>
                                    <InputField
                                        type="custom"
                                        onChange={setLastNames}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td><h1 className="nopad">Email</h1></td>
                                <td>
                                    <input
                                        className="input"
                                        type="email"
                                        onChange={(event) => setEmail(event.target.value)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td><h1 className="nopad">Phone Number</h1></td>
                                <td>
                                    <input
                                        className="input"
                                        type="phone"
                                        onChange={(event) => setPhone(event.target.value)}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <div className="medspace"/>

                    <h1 className="nopad">Address</h1>
                    <AddressFinder
                        address1={{get: address1, set: setAddress1}}
                        address2={{get: address2, set: setAddress2}}
                        town={{get: town, set: setTown}}
                        country={{get: country, set: setCountry}}
                        postcode={{get: postcode, set: setPostcode}}
                    />
                </Card>
            ) : null}

            {progress >= 3 ? (
                <Card
                    title="Consent"
                    buttons={[]}
                >
                    <p>Because your support is vital to us, we would like to stay in touch and keep you informed about our work. We promise to keep your contact details safe.</p>
                        
                    <div className="checkboxgroup">
                        {['email', 'post', 'phone', 'sms'].map((value, index) => (
                            <p key={index}>
                                <span className="checkbox">
                                    <input
                                        className="checkbox"
                                        type="checkbox"
                                        name={`${value}-consent`}
                                        id={`${value}-consent`}
                                        onChange={(e) => handleCheckboxChange(value, e, consents, setConsents)}
                                        checked={consents.includes(value)}
                                    />
                                    <label htmlFor={`${value}-consent`} className="checkbox">
                                        I'd like to hear from you by {value}
                                    </label>
                                </span>
                            </p>
                        ))}
                    </div>
                </Card>
            ) : null}

            {progress >= 4 ? (
                <Card
                    title="Bank Details"
                    buttons={[]}
                >
                    <table className="nopad">
                        <tbody>
                            <tr>
                                <td><h1 className="nopad">Account Holder Name</h1></td>
                                <td>
                                    <InputField
                                        type="custom"
                                        onChange={setAccountName}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td><h1 className="nopad">Sort Code</h1></td>
                                <td>
                                    <InputField
                                        type="custom"
                                        onChange={setSortCode}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td><h1 className="nopad">Account Number</h1></td>
                                <td>
                                    <InputField
                                        type="custom"
                                        onChange={setAccountNumber}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Card>
            ) : null}

            {progress >= 5 ? (
                <Card
                    title="Summary"
                    buttons={[]}
                    
                >
                    <p>Thank you for filling in the form. Before we proceed with your Direct Debit, please check that all your details are correct.</p>
                    <table>
                        <tbody>
                            <tr>
                                <td>Donation Amount:</td>
                                <td>{amount ? '£' + amount : '-'}</td>
                            </tr>
                            <tr>
                                <td>Payment Frequency:</td>
                                <td>{frequency ? frequency : '-'}</td>
                            </tr>
                            <tr>
                                <td>Collection Date:</td>
                                <td>{collectionDate ? collectionDate : '-'}</td>
                            </tr>
                            <tr>
                                <td>Gift Aid:</td>
                                <td>{giftAid ? giftAid : '-'}</td>
                            </tr>
                            <tr>
                                <td>Name:</td>
                                <td>{
                                    title && firstNames && lastNames ?
                                    `${title} ${firstNames} ${lastNames}` :
                                    '-'
                                }</td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td>{email ? email : '-'}</td>
                            </tr>
                            <tr>
                                <td>Phone Number:</td>
                                <td>{phone ? phone : '-'}</td>
                            </tr>
                            <tr>
                                <td>Bank Details:</td>
                                <td>
                                    {accountName ? accountName : '-'}
                                    <br/>
                                    {sortCode ? sortCode : '-'}
                                    <br/>
                                    {accountNumber ? accountNumber : '-'}
                                </td>
                            </tr>
                            <tr>
                                <td>Address Line 1:</td>
                                <td>{address1 ? address1 : '-'}</td>
                            </tr>
                            <tr>
                                <td>Address Line 2:</td>
                                <td>{address2 ? address2 : '-'}</td>
                            </tr>
                            <tr>
                                <td>Town or city:</td>
                                <td>{town ? town : '-'}</td>
                            </tr>
                            <tr>
                                <td>Country:</td>
                                <td>{country ? country : '-'}</td>
                            </tr>
                            <tr>
                                <td>Postcode:</td>
                                <td>{postcode ? postcode : '-'}</td>
                            </tr>
                        </tbody>
                    </table>
                </Card>
            ) : null}

            <div className="centered">
                {progress < 5 ? (
                    <Button handler={handleNext} delay={500} text={`Next ${progress + 1}/5 `}/>
                ) : (
                    <Button handler={handleSubmit} delay={-1} text="Confirm"/>
                )}
            </div>
            
        </div>
    )
}

export default DonationForm
