import React, { useState, useEffect } from 'react'
import './NewCampaign.css'
import InputField from '../Components/InputField'
import Card from './Card'
import { upload } from '../Utils/PureFunctions'
import CampaignPicture from '../Components/CampaignPicture'
import firebase from "firebase";

function EditCampaign(props: {
    db: firebase.firestore.Firestore,
    reload: () => void,
    id: string,
    back: () => void,
}) {
    const [name, setName] = useState<string | null>(null)
    const [description, setDescription] = useState<string | null>(null)
    const [maxAssessmentAge, setMaxAssessmentAge] = useState<number | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        props.db.collection('campaigns').doc(props.id).get().then(doc => {
            if (!doc.exists) {
                setError('Document does not exist')
                return
            }
            if (!doc.data()) {
                setError('Document data is undefined')
                return
            }
            setName(doc.data()!.name)
            setDescription(doc.data()!.description)
            setMaxAssessmentAge(doc.data()!.maxAssessmentAge)
        })
    }, [props.id, props.db])

    const handleSubmit = () => {
        if (name === null || description === null || name.length === 0 || description.length === 0) {
            setError('Please fill in all fields.')
            return
        }
        props.db.collection('campaigns').doc(props.id).update({
            name: name,
            description: description,
            maxAssessmentAge: maxAssessmentAge
        }).then(() => {
            props.reload()
        }).catch(error => {
            setError(error.message)
        })
    }

    const handleDelete = () => {
        props.db.collection('campaigns').doc(props.id).delete().then(props.reload).catch(error => {
            setError(error.message)
            console.log(error)
        })
        // remove the campaigns from the user data
        const id = props.id
        props.db.collection('users').get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
                const userId = doc.id
                const campaigns = doc.data().campaigns as string[]
                if (campaigns.includes(id)) {
                    props.db.collection('users').doc(userId).update({
                        campaigns: campaigns.filter(campaign => campaign !== id)
                    }).catch(setError)
                }
            })
        })
    }

    const handleUploadPicture = () => {
        upload('campaigns', props.id, () => setLoading(true), 500).then(() => {
            props.back()
        }).catch(error => setError(error.message))
    }

    return (
        <Card
            title="Edit Campaign"
            back={props.back}
            buttons={[
                {text: "Upload Picture", delay: 500, handler: handleUploadPicture},
                {text: "Save Changes", delay: -1, handler: handleSubmit},
                {text: "Delete Campaign", delay: 500, handler: handleDelete, confirm: true}
            ]}
            error={error}
            class="newcampaign"
            loading={loading}
        >
            <CampaignPicture id={props.id}/>
            <InputField
                type="custom-value"
                onChange={setName}
                customLabel="Name"
                initial={name ? name : undefined}
            />
            <InputField
                type="textarea-value"
                onChange={setDescription}
                customLabel="Description"
                initial={description ? description : undefined}
            />
            <InputField
                type="number-value"
                onChange={setMaxAssessmentAge}
                customLabel="Hide assessments older than (days)"
                initial={maxAssessmentAge ?? 0}
            />
        </Card>
    )
}

export default EditCampaign
