import React, { useState } from 'react'
import Button from './Button'

function ConfirmButton(props: {
    handler: () => void,
    delay: number,
    text: string,
}) {
    const [showConfirm, setShowConfirm] = useState(false)

    if (showConfirm) { return (
        <Button handler={props.handler} delay={props.delay} text="Are you sure?"/>
    )} else { return (
        <Button handler={() => setShowConfirm(true)} delay={500} text={props.text}/>
    )}
}

export default ConfirmButton
