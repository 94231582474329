import React, {useRef} from 'react'
import Main from '../../Assets/landingpage/main.png'
import bg1 from '../../Assets/landingpage/bg1.svg'
import bg2 from '../../Assets/landingpage/bg2.svg'
import bg3 from '../../Assets/landingpage/bg3.svg'
import bg4 from '../../Assets/landingpage/bg4.svg'
import bg5 from '../../Assets/landingpage/bg5.svg'
import bg6 from '../../Assets/landingpage/bg6.svg'
// import bg7 from '../Assets/landingpage/bg7.svg'
import Users from '../../Assets/landingpage/users.svg'
import Card from '../../Assets/landingpage/card.svg'
import Gears from '../../Assets/landingpage/gears.svg'
import Calendar from '../../Assets/landingpage/calendar.svg'
import Phone from '../../Assets/landingpage/phone.jpg'
import Tablet from '../../Assets/landingpage/tablet.jpg'
import Button from '../../Components/Button'

function Old() {
    const contact = useRef(null)

    const goToContact = () => {
        (contact.current as any).scrollIntoView({behavior: 'smooth'})
    }
    return (
        <div>
            <div className="centre">
                <h1 className="xxhuge">Optimise face-to-face fund&shy;raising the smart way.</h1>
                <h1>raise money for your charity easily from any device and manage the collected data securely.</h1>
            </div>

            <div className="image bg1" style={{backgroundImage: `url(${bg1})`}}/>
            <div className="image main" style={{backgroundImage: `url(${Main})`}}/>

            <div className="grid3x3">
                <Button delay={0} text="Contact us to get started" shadow={true} handler={goToContact}/>
            </div>
            <div className="space1"/>

            {/* <div className="image bg7" style={{backgroundImage: `url(${bg7})`}}></div> */}

            <div className="centre">
                <div className="centered">
                    <div className="box">
                        <img className="icon" src={Users} alt="users"/>
                        <div className="text">
                            Capture Direct Debit and personal information from the public with a customisable form.
                        </div>
                    </div>
                    <div className="box">
                        <img className="icon" src={Card} alt="users"/>
                        <div className="text">
                            Process one-off donations using contactless payments, Google Pay, Apple Pay, and more.
                        </div>
                    </div>
                    <div className="box">
                        <img className="icon" src={Gears} alt="users"/>
                        <div className="text">
                            Manage and report fundraiser activity using powerful administration tools.
                        </div>
                    </div>
                    <div className="box">
                        <img className="icon" src={Calendar} alt="users"/>
                        <div className="text">
                            Quickly sign up members of the public to events with automated emailing promotion.
                        </div>
                    </div>
                </div>
                <div className="bigheight"/>
                <div className="grid3x3">
                    <Button delay={0} text="Get in touch" shadow={true} handler={goToContact}/>
                </div>
                <div className="bigheight"/>
            </div>

            <div className="image bg2" style={{backgroundImage: `url(${bg2})`}}/>
            <div className="centre">
                <div className="devices">
                    <img className="tablet bigshadow" src={Tablet} alt="phone"/>
                    <img className="phone bigshadow" src={Phone} alt="phone"/>
                </div>
            </div>
            <div className="space2"/>

            <div className="bigheight"/>
            <div className="dark">
                <div className="centre centered">
                    <h1 className="xhuge halfwidth left">Collect the information you need</h1>
                    <div className="halfwidth right aligntop">
                        <div className="bigspace"/>
                        Get the Direct Debit form just right using the form editor. Choose and modify existing presets or create one from scratch using input boxes, checkboxes, and more.
                    </div>
                </div>
            </div>

            <div className="image bg3" style={{backgroundImage: `url(${bg3})`}}/>
            <div className="space3"/>


            <div className="accented">
                <div className="bigspace"/>
                <div className="centre centered">
                    <div className="halfwidth left aligntop">
                        <div className="bigspace"/>
                        Collected personal data and account information is stored using military-grade AES-256 encryption.
                        <div className="bigspace"/>
                    </div>
                    <h1 className="xhuge halfwidth right">Rest easy with bulletproof security</h1>
                </div>
                <div className="bigspace"/>
            </div>

            <div className="image bg4" style={{backgroundImage: `url(${bg4})`}}/>
            <div className="space4"/>

            <div className="dark">
                <div className="centre centered">
                    <h1 className="xhuge halfwidth left">Make employee administration effortless</h1>
                    <div className="halfwidth right aligntop">
                        <div className="bigspace"/>
                        Keep track of the number of signups per hour per fundraiser, and have shift start and end times reported directly from inside the system.
                    </div>
                </div>
                <div className="vbigspace"/>
                <div className="grid3x3">
                    <Button delay={0} text="Let's go" shadow={true} handler={goToContact}/>
                </div>
                <div className="vbigspace"/>
            </div>

            <div className="image bg5" style={{backgroundImage: `url(${bg5})`}}/>
            <div className="space5"/>

            <div className="centre centered prices">
                <div className="price shadow">
                    <div className="tier">Tier 1</div>
                    <div className="vbigspace"/>
                    <div className="amount">£???</div>
                    <div className="vbigspace"/>
                    <div className="features">
                        <ul>
                            <li>Simple email capture</li>
                            <li>Automatic email sending</li>
                        </ul>
                    </div>
                </div>
                <div className="price shadow">
                    <div className="tier">Tier 2</div>
                    <div className="vbigspace"/>
                    <div className="amount">£???</div>
                    <div className="vbigspace"/>
                    <div className="features">
                        <ul>
                            <li>Tier 1 features</li>
                            <li>Bank validation</li>
                            <li>Advanced administration tools</li>
                        </ul>
                    </div>
                </div>
                <div className="price shadow">
                    <div className="tier">Extras</div>
                    <div className="vbigspace"/>
                    <div className="amount">£???</div>
                    <div className="centered">Card reader</div>
                    <div className="vbigspace"/>
                    <div className="amount">£???</div>
                    <div className="centered">Tablet + stand</div>
                </div>
            </div>

            <div className="image bg6" style={{backgroundImage: `url(${bg6})`}}/>
            <div className="space6"/>

            <div className="dark">
                <div className="centre centered">
                    <h1 className="xxhuge" ref={contact}>Shoot us an email</h1>
                    <p>
                        To find out more on how to get started, send us an email at:
                        <br/>
                        <span className="selectable">
                                        <a className="email" href="mailto:email@something.com">email@something.com</a>
                                    </span>
                    </p>
                    <div className="bigheight"/>
                    <div className="bigheight"/>
                </div>
            </div>
        </div>
    )
}

export default Old