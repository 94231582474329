import React, { useEffect, useState } from 'react'
import firebase from "firebase";

function CampaignCheckboxes(props: {
    db: firebase.firestore.Firestore,
    onError: (error: string) => void,
    updateSelectedCampaigns: (campaigns: string[]) => void,
    initial: string[],
    selectAll?: boolean
}) {
    const [allCampaigns, setAllCampaigns] = useState<firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]
    | null>(null)
    const [selectedCampaigns, setSelectedCampaigns] = useState(props.initial ? props.initial : [])
    
    const handleCheckboxChange = (id: string, event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.selectAll === true) {
            props.onError('Admins always have access to all campaings.')
            return
        }
        if (event.target.checked) {
            setSelectedCampaigns(selectedCampaigns.concat(id))
            props.updateSelectedCampaigns(selectedCampaigns.concat(id))
        } else {
            setSelectedCampaigns(selectedCampaigns.filter(c => c !== id))
            props.updateSelectedCampaigns(selectedCampaigns.filter(c => c !== id))
        }
    }

    useEffect(() => {
        if (props.selectAll === true && allCampaigns !== null) {
            setSelectedCampaigns(allCampaigns.map(doc => doc.id))
            props.updateSelectedCampaigns(allCampaigns.map(doc => doc.id))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectAll, allCampaigns])

    useEffect(() => {
        let mounted = true

        props.db.collection('campaigns').orderBy('name', 'asc').get().then(snapshot => {
            if (!mounted) return
            setAllCampaigns(snapshot.docs)
        }).catch((error: any) => {
            if (!mounted) return
            props.onError(error.message)
        })
        
        return () => {mounted = false}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="checkboxgroup">
            {allCampaigns === null ? (
                <span>Loading campaigns...</span>
            ) : (
                allCampaigns.map((campaign, index) => (
                    <span className="checkbox" key={index}>
                        <input
                            className="checkbox"
                            type="checkbox"
                            name={campaign.id}
                            id={campaign.id}
                            onChange={(event) => handleCheckboxChange(campaign.id, event)}
                            checked={selectedCampaigns.includes(campaign.id)}
                        />
                        <label htmlFor={campaign.id} className="checkbox">{campaign.data().name}</label>
                    </span>
                ))
            )}
        </div>
    )
}

export default CampaignCheckboxes
