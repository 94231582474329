import React from 'react'
import './UserGuide.css'
import Collapsible from '../Components/Collapsible'
import registration from '../Assets/screenshots/registration.png'
import myaccount from '../Assets/screenshots/myaccount.png'
import changepassword from '../Assets/screenshots/changepassword.png'
import nav from '../Assets/screenshots/nav.png'
import users from '../Assets/screenshots/users.png'
import login from '../Assets/screenshots/login.png'
import forgotpassword from '../Assets/screenshots/forgotpassword.png'
import resetpassword from '../Assets/screenshots/resetpassword.png'
import newuser from '../Assets/screenshots/newuser.png'
import sendlink from '../Assets/screenshots/sendlink.png'
import edituser from '../Assets/screenshots/edituser.png'
import newcampaign from '../Assets/screenshots/newcampaign.png'
import campaign from '../Assets/screenshots/campaign.png'
import editcampaign from '../Assets/screenshots/editcampaign.png'
import forms from '../Assets/screenshots/forms.png'
import donationform from '../Assets/screenshots/donationform.png'
import donationthankyou from '../Assets/screenshots/donationthankyou.png'

const downloadReport = (
    <Collapsible title="Download the campaign report">
        <p>Open the campaign's page, either through the "My Account" page or through the navigation menu.</p>
        <Collapsible type="image"><img src={campaign} alt="campaign"/></Collapsible>
        <p>Click "Download Report". The CSV file can then be opened in a spreadsheet editor.</p>
    </Collapsible>
)

const openForm = (
    <Collapsible title="Open a form for a member of the public">
        <p>Open the campaign's page, either through the "My Account" page or through the navigation menu.</p>
        <Collapsible type="image"><img src={campaign} alt="campaign"/></Collapsible>
        <p>Click "Open Form". This will take you to the form selection page. Note: this page's URL can be shared to anyone, even if they don't have an account.</p>
        <Collapsible type="image"><img src={forms} alt="forms"/></Collapsible>
        <p>Select the type of form you wish to show the member of public.</p>
        <Collapsible type="image"><img src={donationform} alt="donationform"/></Collapsible>
        <p>Once they have finished with the form, click "Next Donation" to reset the form.</p>
        <Collapsible type="image"><img src={donationthankyou} alt="donationthankyou"/></Collapsible>
        <p>Within each form type you can always click "Back" at the top of the form to choose a different type of form. To go back to your account or choose a different campaign, navigate back on your browser. There is no user interface to do this to prevent the member of public accidentally accessing your account.</p>
    </Collapsible>
)

function UserGuide() {
    return (
        <div className="userguide">
            <Collapsible title="All Users" type="huge" opened={true}>
                <Collapsible title="Registering for a new account">
                    <p>Your account must be set up for registration by an Admin, please contact an Admin and provide an email for the account.</p>
                    <p>Once an Admin has set up your account for registration, they will send you a special link that will look similar to this:</p>
                    <p>https://aspect-fs.web.app/register/ti80i3rm0p7mcz0</p>
                    <p>Clicking the link will take you to the registration page. Follow the instructions to complete registration.</p>
                    <Collapsible type="image"><img src={registration} alt="registration"/></Collapsible>
                    <p>Once registration is completed, you will be logged in automatically to your new account.</p>
                </Collapsible>
                <Collapsible title="Changing your password">
                    <p>Open the navigation menu by clicking the hamburger icon in the top left corner.</p>
                    <Collapsible type="image"><img src={nav} alt="navigation menu"/></Collapsible>
                    <p>Click "My Account".</p>
                    <Collapsible type="image"><img src={myaccount} alt="my account"/></Collapsible>
                    <p>Click "Change Password" at the bottom of the page, and enter your new password twice.</p>
                    <Collapsible type="image"><img src={changepassword} alt="change password"/></Collapsible>
                    <p>If your password was changed successfully, you will be redirected back to the "My Account" page.</p>
                </Collapsible>
                <Collapsible title="You've forgotten your password">
                    <p>If you have forgotten your password, you can request a link to reset your password to be sent to your email.</p>
                    <Collapsible type="image"><img src={login} alt="Login"/></Collapsible>
                    <p>Click "Forgot Password?" on the login screen.</p>
                    <Collapsible type="image"><img src={forgotpassword} alt="Forgot Password"/></Collapsible>
                    <p>Then, enter your email to be sent a password reset link. When you receive the email, click the link to reset your password.</p>
                    <Collapsible type="image"><img src={resetpassword} alt="Reset password"/></Collapsible>
                </Collapsible>
                <Collapsible title="Accessing your campaigns">
                    <p>If you are on the "My Account" page, you will see a list of campaigns that you have access to under "My campaign(s)". Clicking one will take you to the campaign page.</p>
                    <Collapsible type="image"><img src={myaccount} alt="my account"/></Collapsible>
                    <p>Alternatively, you can open the navigation menu from anywhere using the hamburger icon in the top left corner. The list of campaigns you have access to will be displayed as individual items in the list.</p>
                    <Collapsible type="image"><img src={nav} alt="navigation menu"/></Collapsible>
                    <p>On the campaign page, you will find the name and description for the campaign, as well as a list of donations made for that campaign.</p>
                    <Collapsible type="image"><img src={campaign} alt="campaign"/></Collapsible>
                </Collapsible>
                <Collapsible title="Signing out">
                    <p>Click the "Sign Out" button in the navigation menu which you can open by clicking the hamburger icon in the top left corner.</p>
                    <Collapsible type="image"><img src={nav} alt="navigation menu"/></Collapsible>
                </Collapsible>
            </Collapsible>

            <Collapsible title="Admins" type="huge" opened={true}>
                <Collapsible title="Create an account for a user">
                    <p>Open the navigation menu by clicking the hamburger icon in the top left corner.</p>
                    <Collapsible type="image"><img src={nav} alt="navigation menu"/></Collapsible>
                    <p>Click "Manage Users".</p>
                    <Collapsible type="image"><img src={users} alt="manage users"/></Collapsible>
                    <p>Then, click "Create New User".</p>
                    <Collapsible type="image"><img src={newuser} alt="new user"/></Collapsible>
                    <p>Enter the user's email address, as well as the type of account and which campaigns they should have access to. Then, click "Create Link".</p>
                    <Collapsible type="image"><img src={sendlink} alt="send link"/></Collapsible>
                    <p>Copy the generated link and send it to your new user. This same link can be found later in the "Edit User" page if you need it again.</p>
                    <Collapsible type="image"><img src={edituser} alt="edituser"/></Collapsible>
                </Collapsible>
                <Collapsible title="Create a new campaign">
                    <p>Open the navigation menu by clicking the hamburger icon in the top left corner.</p>
                    <Collapsible type="image"><img src={nav} alt="navigation menu"/></Collapsible>
                    <p>Click "Create New Campaign".</p>
                    <Collapsible type="image"><img src={newcampaign} alt="newcampaign"/></Collapsible>
                    <p>Fill out the campaign name and description and click "Create". If the campaign creation was successful, you will be redirect you to the "My Account" page, where your newly created campaign will be visible.</p>
                </Collapsible>
                <Collapsible title="Edit a user's details">
                    <p>In the navigation menu (opened by clicking the hamburger icon in the top left corner), click "Manage Users".</p>
                    <Collapsible type="image"><img src={users} alt="manage users"/></Collapsible>
                    <p>Then, click on the user whose details you want to edit.</p>
                    <Collapsible type="image"><img src={edituser} alt="edituser"/></Collapsible>
                    <p>Make your changes and click "Save Changes". If the update succeeded, you will be redirected back to the "Manage Users" page with the user's details updated.</p>
                </Collapsible>
                <Collapsible title="Edit a campaign">
                    <p>Open the campaign's page, either through the "My Account" page or through the navigation menu.</p>
                    <Collapsible type="image"><img src={campaign} alt="campaign"/></Collapsible>
                    <p>Click "Edit Campaign".</p>
                    <Collapsible type="image"><img src={editcampaign} alt="editcampaign"/></Collapsible>
                    <p>Make your changes and click "Save Changes". If the update succeeded, you will be redirect you to the "My Account" page, where your newly updated campaign will be visible.</p>
                </Collapsible>
                <Collapsible title="Delete a user">
                    <p>In the navigation menu (opened by clicking the hamburger icon in the top left corner), click "Manage Users".</p>
                    <Collapsible type="image"><img src={users} alt="manage users"/></Collapsible>
                    <p>Then, click on the user you want to delete.</p>
                    <Collapsible type="image"><img src={edituser} alt="edituser"/></Collapsible>
                    <p>Clieck "Delete User". If the deletion succeeded, you will be redicted to the "Manage Users" page, where you will see that the user no longer exists.</p>
                </Collapsible>
                <Collapsible title="Delete a campaign">
                    <p>Open the campaign's page, either through the "My Account" page or through the navigation menu.</p>
                    <Collapsible type="image"><img src={campaign} alt="campaign"/></Collapsible>
                    <p>Click "Edit Campaign".</p>
                    <Collapsible type="image"><img src={editcampaign} alt="editcampaign"/></Collapsible>
                    <p>Click "Delete Campaign". If the deletion succeeded, you will be redirect you to the "My Account" page, where your will see that the campaign no longer exists.</p>
                </Collapsible>
                {downloadReport}
                {openForm}
            </Collapsible>
            
            <Collapsible title="Clients" type="huge" opened={true}>
                {downloadReport}
            </Collapsible>
            
            <Collapsible title="Fundraisers" type="huge" opened={true}>
                {openForm}
            </Collapsible>
        </div>
    )
}

export default UserGuide
