import React from 'react'
import Footer from "./Footer";
import {TabsEnum} from "./Tabs";
import promotion1 from "../../Assets/landingpage/promotion1.jpg";
import promotion2 from "../../Assets/landingpage/promotion2.jpg";
import promotion3 from "../../Assets/landingpage/promotion3.jpg";
import getText from "../../Utils/Translations";

function Promotion(props: {
    chooseTab: (tab: TabsEnum) => void,
}) {
    return (
        <div>
            <div className="centre">
                <h1 className="xxhuge centered" dangerouslySetInnerHTML={getText("promotionTitle")}/>
            </div>
            <div className="medheight"/>

            <div className="centre">
                <div className="img-text-combo">
                    <img src={promotion3} className="squircle img-text-combo-image" width="300" alt="Services"/>
                    <div className="img-text-combo-content">
                        <h1 className="xhuge" dangerouslySetInnerHTML={getText("promotion1Title")}/>
                        <h1 className="white-text" dangerouslySetInnerHTML={getText("promotion1Text")}/>
                    </div>
                </div>
                <div className="img-text-combo">
                    <img src={promotion2} className="squircle img-text-combo-image" width="300" alt="Services"/>
                    <div className="img-text-combo-content">
                        <h1 className="xhuge" dangerouslySetInnerHTML={getText("promotion2Title")}/>
                        <h1 className="white-text" dangerouslySetInnerHTML={getText("promotion2Text")}/>
                    </div>
                </div>
                <div className="img-text-combo">
                    <img src={promotion1} className="squircle img-text-combo-image" width="300" alt="Services"/>
                    <div className="img-text-combo-content">
                        <h1 className="xhuge" dangerouslySetInnerHTML={getText("promotion3Title")}/>
                        <h1 className="white-text" dangerouslySetInnerHTML={getText("promotion3Text")}/>
                    </div>
                </div>

            </div>
            <div className="medheight"/>

            <Footer chooseTab={props.chooseTab}/>
        </div>
    )
}

export default Promotion