import React from 'react'
import serviceImage from '../../Assets/landingpage/services.jpg'
import Footer from "./Footer";
import {TabsEnum} from "./Tabs";
import getText from "../../Utils/Translations";

function Services(props: {
    chooseTab: (tab: TabsEnum) => void,
}) {
    return (
        <div>
            <div className="centre">
                <h1 className="xxhuge centered" dangerouslySetInnerHTML={getText("servicesTitle")}/>
            </div>
            <div className="medheight"/>

            <div className="accented">
                <div className="medheight"/>
                <div className="centre">
                    <h1 className="xhuge centered" dangerouslySetInnerHTML={getText("servicesAreasOfWork")}/>
                    <div className="medheight"/>
                    <div className="side-by-side">
                        <div className="left-half">
                            <div className="centered">
                                <h1 className="huge" dangerouslySetInnerHTML={getText("area1Title")}/>
                                <span dangerouslySetInnerHTML={getText("area1Text")}/>
                            </div>
                        </div>
                        <div className="right-half">
                            <div className="centered">
                                <h1 className="huge" dangerouslySetInnerHTML={getText("area2Title")}/>
                                <span dangerouslySetInnerHTML={getText("area2Text")}/>
                            </div>
                        </div>
                    </div>
                    <div className="side-by-side">
                        <div className="left-half">
                            <div className="centered">
                                <h1 className="huge" dangerouslySetInnerHTML={getText("area3Title")}/>
                                <span dangerouslySetInnerHTML={getText("area3Text")}/>
                            </div>
                        </div>
                        <div className="right-half">
                            <div className="centered">
                                <h1 className="huge" dangerouslySetInnerHTML={getText("area4Title")}/>
                                <span dangerouslySetInnerHTML={getText("area4Text")}/>
                            </div>
                        </div>
                    </div>
                    <div className="side-by-side">
                        <div className="left-half">
                            <div className="centered">
                                <h1 className="huge" dangerouslySetInnerHTML={getText("area5Title")}/>
                                <span dangerouslySetInnerHTML={getText("area5Text")}/>
                            </div>
                        </div>
                        <div className="right-half">
                            <div className="centered">
                                <h1 className="huge" dangerouslySetInnerHTML={getText("area6Title")}/>
                                <span dangerouslySetInnerHTML={getText("area6Text")}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="medheight"/>
            </div>

            <div className="black-accent">
                <div className="medheight"/>
                <div className="centre">
                    <div className="side-by-side">
                        <div className="left-half grid3x3">
                            <img src={serviceImage} className="squircle" width="350" alt="Services"/>
                        </div>
                        <div className="right-half grid3x3">
                            <div>
                                <h1 className="xhuge" dangerouslySetInnerHTML={getText('servicesTailorTitle')}/>
                                <p dangerouslySetInnerHTML={getText("servicesTailorText")}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer chooseTab={props.chooseTab}/>
        </div>
    )
}

export default Services