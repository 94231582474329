import getEmail from "./GetEmail";

interface Languages {
    en: string,
    es: string
}

const text = {
    // Home page
    homeTitle: {
        en: `Face to Face Fundraising across Scotland`,
        es: `Recaudación de fondos cara a cara en toda Escocia`,
    },
    homeP1: {
        en: `Aspect FS is  a face to face fundraising agency that delivers truly engaging and top-level face to face fundraising.`,
        es: `Aspect FS es una agencia de recaudación de fondos cara a cara que ofrece recaudación de fondos cara a cara verdaderamente cautivador y de alto nivel.`
    },
    homeP2: {
        en: `We have processes in place to ensure that campaigns can be undertaken with social distancing in place please see.`,
        es: `Contamos con procesos seguros para garantizar que las campañas se lleven a cabo con el distanciamiento social. Por favor, consulte`
    },
    covid: {
        en: `Covid-19`,
        es: `Covid-19`
    },
    homeP3: {
        en: `All our teams are fully trained with most of our team members possessing years of face to face fundraising experience while several team members also have experience of managing face to face campaigns.`,
        es: `Todos nuestros equipos están completamente capacitados y la mayoría de los miembros de nuestro equipo poseen años de experiencia en recaudación de fondos cara a cara. Además, varios miembros del equipo también tienen experiencia en la gestión de campañas presenciales.`
    },
    homeP4: {
        en: `We have an internal mystery shopping programme and are committed to promoting best practice within face to face fundraising giving a quality experience to all members of the public we come into contact with.`,
        es: `Tenemos un programa interno de cliente misterioso y nos comprometemos a promover las mejores prácticas en la recaudación de fondos cara a cara, ofreciendo una experiencia de calidad a todos los miembros del público con el que contactemos.`
    },
    homeP4bold: {
        en: `We are committed to achieving zero complaints on all our campaigns.`,
        es: `Nos comprometemos a recibir cero quejas en todas nuestras campañas.`
    },
    homeContact: {
        en: `Contact us on ${getEmail(true)} for a quote or an introduction chat.`,
        es: `Contáctenos en ${getEmail(true)} para una cotización o un chat para presentarnos`
    },

    // Footer
    foot1: {
        en: `Face to Face Fundraising for  Charities - Experienced Dedicated Fundraisers`,
        es: `Recaudación de fondos cara a cara para ONGs - recaudadores de fondos dedicados con experiencia`
    },
    foot2: {
        en: `Aspect FS - Company number: 12298010`,
        es: `Aspect FS - Número de empresa: 12298010`
    },

    // Nav bar
    homeTab: {
        en: `Home`,
        es: `Inicio`
    },
    aboutTab: {
        en: `About`,
        es: `Conócenos`
    },
    covidTab: {
        en: `Covid-19`,
        es: `Covid-19`
    },
    servicesTab: {
        en: `Services`,
        es: `Servicios`
    },
    promotionTag: {
        en: `Promote your brand`,
        es: `Promociona tu marca`
    },
    securityTab: {
        en: `Security`,
        es: `Seguridad`
    },
    contactTab: {
        en: `Contact`,
        es: `Contacto`
    },
    complaintsTab: {
        en: `Complaints`,
        es: `Quejas`
    },
    loginTab: {
        en: `Login`,
        es: `Login`
    },

    // About page
    missionTitle: {
        en: `Mission Statement`,
        es: `Declara&shy;ción de nuestra misión`
    },
    missionText: {
        en: `Our mission is for all team members to become ambassadors for your charity, to embrace your values and to deliver the most professional and inspiring regular giving campaigns imaginable always seeking ways to improve our work.`,
        es: `Nuestra misión es que todos los miembros de nuestro equipo se conviertan en embajadores de su organización, adoptando sus valores y ofreciendo las campañas de donaciones regulares más profesionales e inspiradoras imaginables. También, siempre estamos buscando formas de mejorar nuestro trabajo.`
    },
    values: {
        en: `Our Values`,
        es: `Nuestros Valores`

,    },
    value1Title: {
        en: `Integrity`,
        es: `Honestidad `
    },
    value1Text: {
        en: `We will share our experiences with you, be open and candid and always honour our commitments.`,
        es: `Compartiremos nuestras experiencias contigo, seremos abiertos y sinceros y siempre cumpliremos nuestros compromisos.`
    },
    value2Title: {
        en: `Professionalism`,
        es: `Profesionalidad`
    },
    value2Text: {
        en: `Our behaviours and standards will be of the highest quality always seeking to improve.`,
        es: `Nuestros comportamientos y estándares serán de la más alta calidad, siempre buscando formas de mejorar.`
    },
    value3Title: {
        en: `Fairness and respect`,
        es: `Equidad y respeto`
    },
    value3Text: {
        en: `We will treat all our team members and clients with fairness and respect and always endeavour to do the right thing.`,
        es: `Trataremos a todos los miembros de nuestro equipo y a los clientes con equidad y respeto y siempre nos esforzaremos por hacer lo correcto.`
    },
    value4Title: {
        en: `Dedication`,
        es: `Compromiso`
    },
    value4Text: {
        en: `We will give everything to achieving our target and go the extra mile to promote our clients cause.`,
        es: `Daremos todo lo posible para lograr nuestro objetivo y promover la causa de nuestros clientes.`
    },
    thomasBioTitle: {
        en: `Thomas Lavery is Aspect FS National Campaigns Manager.`,
        es: `Thomas Lavery es el Gerente de Campañas Nacionales de Aspect FS.`
    },
    thomasBio: {
        en: `Thomas has over 15 years of experience working in the regular giving industry both for charities and as a consultant. Thomas has experience developing and managing lottery, direct debit and payroll giving campaigns and has significant experience and knowledge of all face-to-face channels. Thomas has worked in-house with St Andrews’ Hospice and Marie Curie, where he was a Senior Regular Giving Manager, and now working in the agency sector has managed campaigns for local and national charities including Barnardos, The Stroke Association, Chas, St Columba’s Hospice and CHSS.`,
        es: `Thomas tiene más de 15 años de experiencia trabajando en la industria de donaciones regulares para ONGs y también como un asesor. Thomas tiene experiencia en el desarrollo y la gestión de campañas de lotería, débito directo y donaciones de nómina y tiene una gran experiencia y conocimiento de todos los canales presenciales. Thomas ha trabajado internamente con St Andrews’ Hospice y Marie Curie, donde fue el gerente senior de donaciones regulares, y últimamente, trabajando en el sector de agencias, ha administrado campañas para ONGs locales y nacionales, por ejemplo Barnardos, The Stroke Association, Chas, St Columba's. Hospicio y CHSS.`
    },
    richardBioTitle: {
        en: `Richard Yorke is the company founder and will be your contact for reporting, invoicing and overall campaign strategy.`,
        es: `Richard Yorke es el fundador de la empresa y será su contacto para la elaboración de informes, facturación y estrategia general de campaña.`
    },
    richardBio: {
        en: `Richard was previously a Director of Fundraising at Mencap and The Stroke Association and Head of IG at Marie Curie, Age Concern and The British Lung Foundation. Richard also works as a Management Consultant with AAW Partnership where he provides consultancy to National and International clients.`,
        es: `Richard fue anteriormente Director de Recaudación de Fondos en Mencap y The Stroke Association y Director de IG en Marie Curie, Age Concern y The British Lung Foundation. Richard también trabaja como consultor de gestión con AAW Partnership, donde ofrece consultoría a clientes nacionales e internacionales.`
    },
    paulBioTitle: {
        en: `Paul Newton is our Regular Giving Manager for Scotland and the Republic of Ireland.`,
        es: `Paul Newton es nuestro Gerente de donaciones habituales para Escocia y la República de Irlanda.`
    },
    paulBio: {
        en: `Paul moved to Scotland from Ireland in 2010 and immediately transferred his skills into the fundraising business as National Manager for AAP fundraising representing mainly Shelter Scotland before joining Shelter directly in 2014 to establish their successful internal door to door fundraising operation. In 2015, Paul joined Together fundraising to lead a new national venues campaign for Cancer Research UK which ultimately delivered huge returns on both volume and donor retention and lasted for almost 5 years before Paul partnered with Thomas Lavery to help grow and develop Aspect FS. Paul’s standout qualities are leadership, relationship and people management and the recurring ability to take on big challenges and deliver.`,
        es: `Paul se mudó a Escocia desde Irlanda en 2010 e inmediatamente transfirió sus habilidades al negocio de recaudación de fondos como Gerente Nacional para la recaudación de fondos de la AAP representando principalmente a Shelter Scotland antes de unirse a Shelter directamente en 2014 para establecer su exitosa operación interna de recaudación de fondos puerta a puerta. En 2015, Paul se unió a la recaudación de fondos de Together para liderar una nueva campaña nacional para Cancer Research UK que, en última instancia, arrojó enormes beneficios tanto en volumen como en retención de donantes y duró casi 5 años antes de que Paul se asociara con Thomas Lavery para ayudar a crecer y desarrollar Aspect FS. Las cualidades más destacadas de Paul son el liderazgo, las relaciones y la gestión de personas y la capacidad recurrente para asumir grandes desafíos y cumplir.`
    },
    aboutText: {
        en: `Our Campaign Managers have managed face-to-face fundraising campaigns for multiple charities over many years and they lead teams of experienced fundraising professionals.`,
        es: `Nuestros directores de campaña han gestionado campañas de recaudación de fondos cara a cara para varios ONGs durante muchos años y lideran equipos de profesionales expertos en recaudación de fondos.`
    },

    // Covid page
    covidTitle: {
        en: `How have we Prepared?`,
        es: `¿Cómo nos hemos prepa&shy;rado?`
    },
    covidText: {
        en: `We have undertaken thorough preparation for fundraising with social distancing in place. We will also engage with our clients to tailor our approach to ensure we are meeting any additional requirements you may have.`,
        es: `Hemos realizado una preparación rigurosa para la recaudación de fondos con el distanciamiento social. También colaboramos con nuestros clientes para adaptar nuestro enfoque y asegurarnos de que estamos cumpliendo con cualquier requisito adicional que puedan tener.`
    },
    covid1Title: {
        en: `PPE`,
        es: `EPP`
    },
    covid1Text: {
        en: `All our fundraisers will be provided with PPE which has been fully risk assessed with guidance to use and upkeep provided and monitored for all fundraisers`,
        es: `Todos nuestros recaudadores de  fondos recibirán un equipo de protección personal que ha sido evaluado en su totalidad en función del riesgo con orientación para su uso y mantenimiento proporcionado y monitoreado.”`
    },
    covid2Title: {
        en: `Social Distancing`,
        es: `Distan&shy;ciamiento social`
    },
    covid2Text: {
        en: `As well as training on fundraising successfully with social distancing and ongoing monitoring we will provide all our fundraisers with practical solutions to ensure that the required physical distance between fundraiser and potential donor is respected. With any campaign we run we will ensure that social distancing is adhered to at all times`,
        es: `Además de la formación sobre recaudación de fondos con distanciamiento social y supervisión continua, proporcionaremos a todos nuestros recaudadores de fondos soluciones prácticas para garantizar que se respeten la distancia física requerida entre el recaudador de fondos y el donante potencial. Con cualquier campaña que realicemos, nos aseguraremos de que se respete en todo momento el distanciamiento social`
    },
    covid3Title: {
        en: `Risk Assessment`,
        es: `Evaluación de riesgos`
    },
    covid3Text: {
        en: `We have undertaken vigarous risk assessments of every site and approach we will undertake and have built in safeguards and continious daily reviews of our activity. We provide reassurance to our clients through our reporting mechanism that no infringments have taken place.`,
        es: `Hemos llevado a cabo rigurosas evaluaciones de riesgo de cada sitio y estrategia que utilizaremosy hemos incorporado revisiones diarias continuas de nuestra actividad. Proporcionamos tranquilidad a nuestros clientes a través de nuestro mecanismo de denuncia de que no se han producido infracciones.`
    },

    // Services page
    servicesTitle: {
        en: `A F2F agency that delivers in-house skills`,
        es: `Una agen&shy;cia de recau&shy;dación de fondos que ofrece habili&shy;dades internas`
    },
    servicesAreasOfWork: {
        en: `Areas of work`,
        es: `Áreas de trabajo`
    },
    area1Title: {
        en: `Door to Door`,
        es: `Puerta a puerta`
    },
    area1Text: {
        en: `We will tailor a campaign that meets you needs in ensuring that we are working in areas with your target audiences. We also are constantly trialing new areas and see outstanding success in locations that have had no to little previous door to door campaigns by agencies.`,
        es: `Adaptaremos una campaña que satisfaga sus necesidades para asegurarnos de que estamos trabajando en áreas con su público objetivo. También estamos constantemente probando nuevas áreas y vemos un éxito sobresaliente en ubicaciones que no han tenido campañas  “puerta a puerta” previas por parte de las agencias.`
    },
    area2Title: {
        en: `Consultancy`,
        es: `Consultoría`
    },
    area2Text: {
        en: `We can work with you to review your in-house operation and also provide ongoing support to ensure that your team is operating as efficiently as possible. We have undertaken these projects with multiple clients as well as having run in-house teams directly.`,
        es: `Podemos trabajar con usted para evaluar su operación interna y también proveer apoyo continuo para asegurar que su equipo esté operando de la manera más eficiente posible. Hemos realizado estos proyectos con múltiples clientes, además de haber dirigido equipos internos directamente.`
    },
    area3Title: {
        en: `Venues`,
        es: `Localizaciones`
    },
    area3Text: {
        en: `Unrivaled knowledge of the venues  that consistently combine footfall with audiences inclined to charitable giving. Our years of experience means we know which sites will work for our clients.`,
        es: `Conocimiento incomparable de los lugares que combinan constantemente una alta cantidad de clientes inclinados por dar a ONGs. Nuestros años de experiencia significan que sabemos cuáles sitios funcionarán para nuestros clientes.`
    },
    area4Title: {
        en: `Street`,
        es: `Calle`
    },
    area4Text: {
        en: `We know the best locations  to deliver a successful campaign on your behalf. Successful street fundraising is a combination of experienced fundraisers in proven sites. We can provide this to you.`,
        es: `Conocemos las mejores ubicaciones para realizar una campaña exitosa. La recaudación de fondos exitosa en la calle es una combinación de recaudadores de fondos expertos en sitios probados.`
    },
    area5Title: {
        en: `Cause`,
        es: `Causa`
    },
    area5Text: {
        en: `We will understand your cause and can assist you in translating your message so that it works for face to face fundraising whilst delivering recruitment campaigns of any volume.`,
        es: `Entenderemos su causa y podemos ayudar a traducir su mensaje para que funcione para la recaudación de fondos cara a cara, mientras realizamos campañas de reclutamiento de cualquier volumen.`
    },
    area6Title: {
        en: `Lottery`,
        es: `Lotería`
    },
    area6Text: {
        en: `We can deliver the new lottery players you need to build your player base whilst also delivering your wider organisational message.`,
        es: `Podemos ofrecer los nuevos jugadores de lotería que necesita para construir su base de jugadores y, al mismo tiempo, transmitir el mensaje de su organización.`
    },
    servicesTailorTitle: {
        en: `We can tailor our approach to work with you on any campaign`,
        es: `Podemos adaptar nuestro método para trabajar con usted en cualquier campaña`
    },
    servicesTailorText: {
        en: `Get in touch on ${getEmail(false)} and we will provide a quote within 24 hours.`,
        es: `Póngase en contacto en ${getEmail(false)} y le proporcionaremos una cotización dentro de 24 horas.`
    },

    // Promotion page
    promotionTitle: {
        en: `Where we work and how`,
        es: `Donde y como tra&shy;ba&shy;ja&shy;mos`
    },
    promotion1Title: {
        en: `Traditional F2F`,
        es: `Cara a cara tradicional`
    },
    promotion1Text: {
        en: `Raising awareness of charity services, recruiting new supporters to the charity using pop up banners and promotional stand in smaller sites and more intimate locations including corporate sites.`,
        es: `Sensibilización sobre los servicios de ONGs, reclutando nuevos partidarios utilizando banners publicatarios y stands promocionales en sitios más pequeños y lugares más íntimos, incluso sitios corporativos.`
    },
    promotion2Title: {
        en: `Immersive`,
        es: `Inmersivo`
    },
    promotion2Text: {
        en: `Raising awareness of charity services, recruiting new supporters to the charity in larger locations using large scale branding and providing marketing opportunities through social media.`,
        es: `Concienciar sobre los servicios de las ONGs, reclutar nuevos partidarios en ubicaciones más grandes utilizando material marketing a gran escala y proporcionando oportunidades de marketing a través de las redes sociales.`
    },
    promotion3Title: {
        en: `Expe&shy;ri&shy;en&shy;tial`,
        es: `Expe&shy;ri&shy;men&shy;tal`
    },
    promotion3Text: {
        en: `Raising awareness of charity services, recruiting new supporters to the charity in larger locations using educational or fun concepts to engage with the public.`,
        es: `Crear conciencia sobre los servicios de la ONG, reclutar nuevos partidarios en lugares más grandes utilizando conceptos educativos o divertidos para interactuar con el público.`
    },

    // Security page
    securityTitle: {
        en: `How we keep your data safe`,
        es: `Cómo man&shy;te&shy;ne&shy;mos sus datos seguros`
    },
    securityText1: {
        en: `Aspect FS use cutting edge digital platforms provided by industry leaders ensuring the capture, validation and processing of new donors is safe and straightforward.`,
        es: `Aspect FS utiliza plataformas digitales innovadoras proporcionadas por líderes de la industria, que garantizan que la captura, validación y procesamiento de nuevos donantes sea segura y sencilla.`
    },
    securityText2: {
        en: `The reporting is immediate and all our clients have secure access to data files. Aspect FS have strict Data Protection Guidelines in place and adhere to all GDPR regulations.`,
        es: `La notificación es inmediata y todos nuestros clientes tienen acceso seguro a los archivos de datos. Aspect FS cuenta con estrictas directrices de protección de datos y se adhiere a todas las normativas del reglamento GDPR.`
    },
    securityText3: {
        en: `Our platforms allow contactless sign up and give our supporters the choice of how they can sign up and when.`,
        es: `Nuestras plataformas permiten el registro sin contacto y proveen a nuestros donantes  la flexibilidad de cómo y cuándo registrarse.`
    },
    securityText4: {
        en: `Our clients have access to our risk assessment platform where they can observe our risk assessments in real time knowing that the strictest of protocols are being implemented before we work and throughout the day.`,
        es: `Nuestros clientes tienen acceso a nuestra plataforma de evaluación de riesgos donde pueden observar nuestras evaluaciones de riesgos en tiempo real sabiendo que se están implementando los protocolos más estrictos antes de que empecemos a trabajar y durante todo el día.`
    },

    // Complaints page
    complaintsTitle: {
        en: `Complaints and Escalation Process`,
        es: `Proceso de quejas y escalada`
    },
    complaintsText: {
        en: `Click to download our Complaints and Escalation Process`,
        es: `Haga clic para descargar nuestro proceso de quejas y escalada`
    },
    complaintsButtonText: {
        en: `Download`,
        es: `Descargar`
    },

    // Contact page
    contactTitle: {
        en: `Respon&shy;ding to your needs`,
        es: `Respon&shy;dien&shy;do a sus nece&shy;si&shy;da&shy;des`
    },
    contactText: {
        en: `We will provide a quote to you within 24 hours or alternatively set up an introduction chat about the services we provide. Our National Campaigns Manager, Thomas Lavery can be reached on ${getEmail(true)}`,
        es: `Le proporcionaremos un presupuesto dentro de las 24 horas o, alternativamente, organizaremos un chat para presentarnos sobre los servicios que ofrecemos . Se puede contactar con Gerente Nacional de Campañas, Thomas Lavery en ${getEmail(true)}`
    },
}

function getText(id: keyof typeof text) {
    const language = localStorage.getItem('language')
    if (language === null || language === '') {
        return {__html: text[id].en}
    } else {
        return {__html: text[id][language as keyof Languages]}
    }
}

export default getText
