import React from 'react'
import Footer from "./Footer";
import {TabsEnum} from "./Tabs";
import thumbsup from '../../Assets/landingpage/thumbsup.svg'
import mysteryshopping from '../../Assets/landingpage/mysteryshopping.svg'
import covid from '../../Assets/landingpage/covid.svg'
import training from '../../Assets/landingpage/training.svg'
import mainPic from '../../Assets/landingpage/home-pic.jpg'
import Logo from "../../Assets/logo.png";
import getText from "../../Utils/Translations";

function Home(props: {
    chooseTab: (tab: TabsEnum) => void,
}) {
    return (
        <div>

            <img src={mainPic} alt="main" className="main-pic"/>
            <div className="home-image-space"/>
            <div className="">
                <div className="centre card">
                    <div className="smallheight"/>
                    <div className="centered">
                        <img src={Logo} className="logo" alt="Logo" width="300"/>
                    </div>
                    <div className="smallheight"/>
                    <h1 className="huge centered" dangerouslySetInnerHTML={getText('homeTitle')}/>
                    <div className="smallheight"/>
                    <div className=" side-by-side">
                        <div className="left-half">
                            <div className="centered">
                                <img className="home-icon" src={thumbsup} alt="Thumbs up"/>
                            </div>
                            <p className="centered" dangerouslySetInnerHTML={getText('homeP1')}/>
                        </div>
                        <div className="right-half">
                            <div className="centered">
                                <img className="home-icon" src={covid} alt="Covid 19"/>
                            </div>
                            <p className="centered">
                                <span dangerouslySetInnerHTML={getText('homeP2')}/>
                                <span
                                    className="link"
                                    onClick={() => props.chooseTab(TabsEnum.covid19)}
                                    dangerouslySetInnerHTML={getText('covid')}
                                />.
                            </p>
                        </div>
                    </div>
                    <div className="centre side-by-side">
                        <div className="left-half">
                            <div className="centered">
                                <img className="home-icon" src={training} alt="Training"/>
                            </div>
                            <p className="centered" dangerouslySetInnerHTML={getText('homeP3')}/>
                        </div>
                        <div className="right-half">
                            <div className="centered">
                                <img className="home-icon" src={mysteryshopping} alt="Mystery Shopping"/>
                            </div>
                            <p className="centered">
                                <span dangerouslySetInnerHTML={getText('homeP4')}/>
                                <br/>
                                <b dangerouslySetInnerHTML={getText("homeP4bold")}/>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="medheight"/>
            </div>

            <div className="accented">
                <div className="bigheight"/>
                <div className="centered black-text" dangerouslySetInnerHTML={getText('homeContact')}/>
                <div className="bigheight"/>
            </div>

            <Footer chooseTab={props.chooseTab}/>
        </div>
    )
}

export default Home