import React, { useState, useEffect } from 'react'
import './EditAssessment.css'
import Card from './Card'
import firebase from "firebase";
import {getDateFormat} from "../Utils/PureFunctions";
import InputField from "../Components/InputField";
import {canUploadRiskAssessment} from "../Utils/Privileges";

function EditAssessment(props: {
    db: firebase.firestore.Firestore,
    reload: () => void,
    id: string,
    folder: 'assessments' | 'documents'
    back: () => void,
    accountType: string
}) {
    const [username, setUsername] = useState<string | null>(null)
    const [filename, setFilename] = useState<string | null>(null)
    const [createdTime, setCreatedTime] = useState<number | null>(null)
    const [picture, setPicture] = useState<string | null>(null)
    const [notes, setNotes] = useState<string | undefined>(undefined)
    const [error, setError] = useState<string | null>(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        props.db.collection(props.folder).doc(props.id).get().then(doc => {
            if (!doc.exists) {
                setError('Document does not exist')
                return
            }
            if (!doc.data()) {
                setError('Document data is undefined')
                return
            }
            setPicture(doc.data()!.url)
            setCreatedTime(doc.data()!.uploadDate)
            setFilename(doc.data()!.filename)
            setNotes(doc.data()!.notes)
            setUsername(doc.data()!.username)
            setLoading(false)

        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id, props.db])

    const handleSubmit = () => {
        props.db.collection(props.folder).doc(props.id).update({
            notes: notes
        }).then(() => {
            props.back()
        }).catch(error => {
            setError(error.message)
        })
    }

    const handleDelete = () => {
        const deleteFromDB = props.db.collection(props.folder).doc(props.id).delete()
        const storageRef = firebase.storage().ref()
        const assessmentRef = storageRef.child(`${props.folder}/${filename}`)
        const deleteFromStorage = assessmentRef.delete()
        Promise.all([deleteFromStorage, deleteFromDB]).then(props.back).catch(error => {
            setError(error.message)
            console.log(error)
        })
    }

    const handleDownload = () => {
        if (picture === null) return
        window.open(picture)
    }

    return (
        <Card
            title="Edit Assessment"
            back={props.back}
            buttons={[
                canUploadRiskAssessment(props.accountType) ? {
                    text: 'Save Changes',
                    delay: -1,
                    handler: handleSubmit
                } : null,
                canUploadRiskAssessment(props.accountType) ? {
                    text: `Delete ${props.folder === 'assessments' ? 'Risk Assessment' : 'Document'}`,
                    delay: 500,
                    handler: handleDelete,
                    confirm: true,
                } : null,
                {
                    text: 'Download',
                    delay: 500,
                    handler: handleDownload,
                }
            ]}
            error={error}
            class="edit-assessment"
            loading={loading}
        >
            <div className="grid3x3">
                <a href={picture ?? ''} target="_blank" rel="noopener noreferrer" className="link">
                    {props.folder === 'assessments' ? (
                        <img className="assessment-picture" src={picture ?? ''} alt="Risk Assessment"/>
                    ) : (
                        <div className="open-document">Open {filename}</div>
                    )}
                </a>
            </div>

            <table>
                <tbody>
                    <tr>
                        <td><b>Created by:</b></td>
                        <td className="selectable">{username}</td>
                    </tr>
                    <tr>
                        <td><b>Created on:</b></td>
                        <td className="selectable">{getDateFormat(createdTime ?? 0)}</td>
                    </tr>
                </tbody>
            </table>

            <div style={{pointerEvents: canUploadRiskAssessment(props.accountType) ? 'all' : 'none'}}>
                <InputField
                    type="textarea-value"
                    onChange={setNotes}
                    customLabel="Notes"
                    initial={notes}
                />
            </div>
        </Card>
    )
}

export default EditAssessment
