import React, {useState} from 'react'
import './Login.css'
import Logo from '../Assets/logo.png'
import Button from '../Components/Button'

import * as firebase from "firebase/app"
import ErrorMessage from '../Components/ErrorMessage'

function ForgotPassword(props: {
    back: () => void
}) {
    const [email, setEmail] = useState('')
    const [buttonKey, setButtonKey] = useState(0)
    const [errorMessage, setErrorMessage] = useState('')
    const [emailSent, setEmailSent] = useState(false)

    const resetButton = () => {
        setButtonKey(buttonKey + 1)
    }
    
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
    }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        firebase.auth().sendPasswordResetEmail(
            email,
            { url: window.location.href.split(/[?#]/)[0] }
        ).then(() => {
            setEmailSent(true)
        }).catch((error) => {
            // reset the sign in button to remove the spinner
            resetButton()
            setErrorMessage(error.message)
            console.log({error})
        })
    }

    const handleInvaidSubmit = () => {
        resetButton()
    }

    if (emailSent) return (
        <div className="login" data-testid="login">
            <div className="grid3x3">
                <img className="login-logo" src={Logo} alt="Logo" data-testid="login-logo"/>
            </div>
            <h1 className="centered">An email has been send to {email} with instructions to reset your password.</h1>
            <div className="grid3x3" data-testid="sign-in">
                <Button handler={props.back} text="Back To Login" delay={500} key={buttonKey}/>
            </div>
        </div>
    )

    return (
        <div className="login" data-testid="login">
            <div className="grid3x3">
                <img className="login-logo" src={Logo} alt="Logo" data-testid="login-logo"/>
            </div>
            <form onSubmit={handleSubmit} onInvalid={handleInvaidSubmit}>

                <label className="input" htmlFor="email">Email</label>
                <input
                    className="input"
                    id="email"
                    type="email"
                    name="email"
                    autoComplete="email"
                    onChange={handleEmailChange}
                    data-testid="email-field"
                    autoFocus
                    required
                />

                <ErrorMessage message={errorMessage}/>
                
                <div className="grid3x3" data-testid="sign-in">
                    <Button text="Send Password Reset Email" delay={-1} key={buttonKey}/>
                </div>
            </form>

            <div className="bigspace"/>

            <div className="centered">
                <div
                    className="forgot"
                    onClick={props.back}
                >Back to login</div>
            </div>
        </div>
    )
}

export default ForgotPassword
