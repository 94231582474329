import React, { useState, useEffect } from 'react'
import './DonationForm.css'
import Button from '../../Components/Button'
import Spinner from '../../Components/Spinner'
import ErrorMessage from '../../Components/ErrorMessage'
import { cleanURL } from '../../Utils/PureFunctions'
import CustomRadio from '../../Components/CustomRadio'
import InputField from '../../Components/InputField'
import firebase from 'firebase'
import Card from '../../Cards/Card'
import AddressFinder from '../../Components/AddressFinder'
import TitleWithBack from '../../Components/TitleWithBack'
import CampaignPicture from '../../Components/CampaignPicture'

function CallBackForm(props: {
    id: string,
    db: firebase.firestore.Firestore,
    back: () => void,
}) {
    const [progress, setProgress] = useState(0)
    const [campaign, setCampaign] = useState<firebase.firestore.DocumentData | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [submitted, setSubmitted] = useState(false)
    
    const [title, setTitle] = useState<string | null>(null)
    const [firstNames, setFirstNames] = useState<string | null>(null)
    const [lastNames, setLastNames] = useState<string | null>(null)
    const [email, setEmail] = useState<string | null>(null)
    const [phone, setPhone] = useState<string | null>(null)
    const [consents, setConsents] = useState<string[]>([])

    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const [town, setTown] = useState('')
    const [country, setCountry] = useState('')
    const [postcode, setPostcode] = useState('')
    

    useEffect(() => {
        let mounted = true
        props.db.collection('campaigns').doc(props.id).get().then(doc => {
            if (!mounted) return
            if (!doc.exists) {
                setError('This campaign does not exist.')
                return
            }
            if (!doc.data()) {
                setError('Campaign document data is falsy.')
                return
            }
            setCampaign(doc)
        }).catch(error => {
            setError(error.message)
            console.log({error})
        })
        return () => {mounted = false}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const resetForm = () => {
        props.back()
    }
    
    const handleCheckboxChange = (
        value: string,
        event: React.ChangeEvent<HTMLInputElement>,
        array: string[],
        setArray: (array: string[]) => void,
    ) => {
        if (event.target.checked) {
            setArray(array.concat(value))
        } else {
            setArray(array.filter(v => v !== value))
        }
    }
    
    const handleSubmit = () => {
        props.db.collection('callbacks').add({
            title,
            firstNames,
            lastNames,
            email,
            phone,
            address1,
            address2,
            town,
            country,
            postcode,
            consents,
        }).then(() => {
            setSubmitted(true)
        }).catch(error => {
            setError(error.message)
            console.log({error})
        })
        setSubmitted(true)
    }

    if (error !== null) return (
        <div className="centered">
            <ErrorMessage message={error}/>
            <Button handler={cleanURL} delay={500} text="Go To Login"/>
        </div>
    )
    
    if (submitted) return (
        <div className="donationform">
            <h1>Confirmation</h1>
            <div className="solid">
                <p>Thank you for getting in touch!</p>
            </div>
            <div className="centered">
                <Button handler={resetForm} delay={500} text="Next Donation"/>
            </div>
        </div>
    )
    
    if (campaign === null || campaign.data() === undefined) return (<Spinner/>)

    return (
        <div className="donationform">
        <TitleWithBack text="Back" onClick={props.back}/>
        <CampaignPicture id={props.id}/>
            <h1 className="huge centered">Call back later</h1>

            <div className="medspace"/>

            {progress >= 0 ? (
                <Card
                title="Your Details"
                buttons={[]}
            >
                <h1 className="nopad">Title</h1>
                <CustomRadio
                    name="title"
                    values={[
                        { name: 'Mr', value: 'Mr'},
                        { name: 'Ms', value: 'Ms'},
                        { name: 'Mrs', value: 'Mrs'},
                    ]}
                    otherType="text"
                    onChange={setTitle}
                />

                <table className="nopad">
                    <tbody>
                        <tr>
                            <td><h1 className="nopad">First Name(s)</h1></td>
                            <td>
                                <InputField
                                    type="custom"
                                    onChange={setFirstNames}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td><h1 className="nopad">Last Name(s)</h1></td>
                            <td>
                                <InputField
                                    type="custom"
                                    onChange={setLastNames}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td><h1 className="nopad">Email</h1></td>
                            <td>
                                <input
                                    className="input"
                                    type="email"
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td><h1 className="nopad">Phone Number</h1></td>
                            <td>
                                <input
                                    className="input"
                                    type="phone"
                                    onChange={(event) => setPhone(event.target.value)}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="medspace"/>

                <h1 className="nopad">Address</h1>
                <AddressFinder
                    address1={{get: address1, set: setAddress1}}
                    address2={{get: address2, set: setAddress2}}
                    town={{get: town, set: setTown}}
                    country={{get: country, set: setCountry}}
                    postcode={{get: postcode, set: setPostcode}}
                />
            </Card>
            ) : null}

            {progress >= 1 ? (
                <Card
                    title="Consent"
                    buttons={[]}
                >
                    <p>Because your support is vital to us, we would like to stay in touch and keep you informed about our work. We promise to keep your contact details safe.</p>
                        
                    <div className="checkboxgroup">
                        {['email', 'post', 'phone', 'sms'].map((value, index) => (
                            <p key={index}>
                                <span className="checkbox">
                                    <input
                                        className="checkbox"
                                        type="checkbox"
                                        name={`${value}-consent`}
                                        id={`${value}-consent`}
                                        onChange={(e) => handleCheckboxChange(value, e, consents, setConsents)}
                                        checked={consents.includes(value)}
                                    />
                                    <label htmlFor={`${value}-consent`} className="checkbox">
                                        I'd like to hear from you by {value}
                                    </label>
                                </span>
                            </p>
                        ))}
                    </div>
                </Card>
            ) : null}

            <div className="centered">
                {progress < 1 ? (
                    <Button handler={() => setProgress(progress + 1)} delay={500} text={`Next ${progress + 1}/2 `}/>
                ) : (
                    <Button handler={handleSubmit} delay={-1} text="Confirm"/>
                )}
            </div>
            
        </div>
    )
}

export default CallBackForm
