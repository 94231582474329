const admin = 'admin'
const client = 'client'
const fundraiser = 'fundraiser'

const canOpenDonationForm = (type: string) => type === admin || type === fundraiser

const canDownloadCampaign = (type: string) => type === admin || type === client

const canManageUsers = (type: string) => type === admin

const canCreateNewCampaign = (type: string) => type === admin

const forceAllCampaignAccess = (type: string | null) => type === admin

const canUploadRiskAssessment = (type: string) => type === admin

export {
    canOpenDonationForm,
    canDownloadCampaign,
    canManageUsers,
    canCreateNewCampaign,
    forceAllCampaignAccess,
    canUploadRiskAssessment,
}
