import React, { useState } from 'react'
import './AddressFinder.css'
import InputField from './InputField'
import { searchAddress, placeDetails, debounce } from '../Utils/PureFunctions'
import Spinner from './Spinner'

const findInAddressComponents = (type: string, addressComponents: {
    long_name: string,
    short_name: string,
    types: string[],
}[]) => {
    for (const component in addressComponents) {
        if (Object.prototype.hasOwnProperty.call(addressComponents, component)) {
            const element = addressComponents[component];
            if (element.types.includes(type)) {
                return element.long_name
            }
        }
    }
}

function AddressFinder(props: {
    address1:   {get: string, set: React.Dispatch<React.SetStateAction<string>>},
    address2:   {get: string, set: React.Dispatch<React.SetStateAction<string>>},
    town:       {get: string, set: React.Dispatch<React.SetStateAction<string>>},
    country:    {get: string, set: React.Dispatch<React.SetStateAction<string>>},
    postcode:   {get: string, set: React.Dispatch<React.SetStateAction<string>>},
}) {
    const [loading, setLoading] = useState(false)
    const [showing, setShowing] = useState<'autocomplete' | 'manual'>('autocomplete')
    const [predictions, setPredictions] = useState<any[] | null>(null)
    // const [selectedPlace, setSelectedPlace] = useState<string | null>(null)
      
    const debounced = debounce((input) => {
        console.log('RUN')
        searchAddress(input).then((res: any) => {
            setPredictions(res.predictions)
            setLoading(false)
        }).catch(error => console.log(error))
    }, 1000)

    const handleSetInput = (input: string) => {
        setLoading(true)
        setShowing('autocomplete')
        debounced(input)
    }

    const handleEnterManual = () => {
        props.address1.set('')
        props.town.set('')
        props.country.set('')
        props.postcode.set('')
        setShowing('manual')
        setLoading(false)
    }

    const handleBackToSearch = () => {
        setShowing('autocomplete')
        setPredictions(null)
    }

    const handleClickPlace = (id: string) => {
        setLoading(true)
        placeDetails(id).then((res: any) => {
            const addressComponents = res.result.address_components
            console.log(res)
            setShowing('manual')

            const streetNumber = findInAddressComponents('street_number', addressComponents)
            const route =        findInAddressComponents('route',         addressComponents)
            const town =         findInAddressComponents('postal_town',          addressComponents)
            const country =      findInAddressComponents('country',       addressComponents)
            const postCode =     findInAddressComponents('postal_code',   addressComponents)

            props.address1.set(`${streetNumber ? streetNumber + ' ' : ''}${route ? route : ''}`)
            props.town.set(town ? town : '')
            props.country.set(country ? country : '')
            props.postcode.set(postCode ? postCode : '')

            setLoading(false)
        }).catch(error => console.log(error))
    }

    return (
        <div className="addressfinder">
            {showing === 'autocomplete' ? (
                <InputField
                    type="custom"
                    customLabel="Start typing your full address"
                    onChange={handleSetInput}
                    disableAutoComplete={true}
                />
            ) : null}
            {loading ? (
                <Spinner/>
            ) : (
                showing === 'autocomplete' ? (
                    
                    predictions === null ? (
                        <span className="link" onClick={handleEnterManual}>Enter address manually</span>
                    ) : (
                        predictions.length === 0 ? (
                            <div>
                                <span className="message">No results</span>
                                <span className="link" onClick={handleEnterManual}>Enter address manually</span>
                            </div>
                        ) : (
                            predictions.map((pred: any, index) => (
                                <div
                                    onClick={() => handleClickPlace(pred.place_id)}
                                    className="link"
                                    key={index}
                                >{pred.description}</div>
                            ))
                        )
                    )
                ) : (
                    <div className="fields">
                        <InputField
                            type="custom-value"
                            customLabel="Address Line 1"
                            onChange={props.address1.set}
                            initial={props.address1.get}
                        />
                        <InputField
                            type="custom-value"
                            customLabel="Address Line 2"
                            onChange={props.address2.set}
                            initial={props.address2.get}
                        />
                        <InputField
                            type="custom-value"
                            customLabel="Town or city"
                            onChange={props.town.set}
                            initial={props.town.get}
                        />
                        <InputField
                            type="custom-value"
                            customLabel="Country"
                            onChange={props.country.set}
                            initial={props.country.get}
                        />
                        <InputField
                            type="custom-value"
                            customLabel="Post code"
                            onChange={props.postcode.set}
                            initial={props.postcode.get}
                        />
                        <span className="link" onClick={handleBackToSearch}>Back to search</span>
                    </div>
                )
            )}
        </div>
    )
}

export default AddressFinder
