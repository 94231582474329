import React, { useState } from 'react'
import './EditUser.css'
import * as firebase from "firebase/app"
import RoleRadioButtons from '../Components/RoleRadioButtons'
import CampaignCheckboxes from '../Components/CampaignCheckboxes'
import { registrationLink, upload } from '../Utils/PureFunctions'
import { forceAllCampaignAccess } from '../Utils/Privileges'
import Card from './Card'
import InputField from '../Components/InputField'
import ProfilePicture from '../Components/ProfilePicture'

function EditUser(props: {
    user: firebase.firestore.DocumentData,
    db: firebase.firestore.Firestore,
    back: () => void
    myUserData: firebase.firestore.DocumentData
}) {
    const [name, setName] = useState(props.user.data().name ? props.user.data().name : '')
    const [accountType, setAccountType] = useState(props.user.data().role)
    const [altered, setAltered] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [campaigns, setCampaigns] = useState<string[]>(props.user.data().campaigns)
    const [loading, setLoading] = useState(false)

    const email = props.user.id

    const handleRadioChange = (role: string) => {
        if (props.user.id === firebase.auth().currentUser?.email) {
            setError('You cannot change your own account type')
            return
        }
        setAccountType(role)
        setAltered(true)
    }

    const handleSetCampaign = (campaigns: string[]) => {
        setAltered(true)
        setCampaigns(campaigns)
    }

    const handleSave = () => {
        props.db.collection('users').doc(props.user.id).update({
            role: accountType,
            campaigns: campaigns,
            name: name,
        }).then(() => {
            props.back()
        }).catch(error => {
            setError(error.message)
        })
    }

    const handleDeleteUser = () => {
        props.db.collection('users').doc(props.user.id).delete().then(() => {
            props.back()
        }).catch(error => {
            setError(error.message)
        })
    }

    const handleSetName = (name: string) => {
        setName(name)
        setAltered(true)
    }

    const handleUploadImage = () => {
        upload('profiles', props.user.id, () => setLoading(true), 500).then(() => {
            props.back()
        }).catch(error => setError(error.message))
    }

    return (
        <Card
            title="Edit User"
            buttons={[
                {handler: handleUploadImage, delay: 500, text: "Upload Profile Picture"},
                altered ? {handler: handleSave, delay: 500, text: "Save Changes"} : null,
                props.user.id !== firebase.auth().currentUser?.email ? {
                    handler: handleDeleteUser, delay: 500, text: "Delete User", confirm: true
                } : null,
            ]}
            error={error}
            back={props.back}
            class="edituser"
            loading={loading}
        >
            <ProfilePicture
                email={email}
                name={name}
                onClick={handleUploadImage}
            />
            <InputField
                onChange={handleSetName}
                type="custom-value"
                initial={name}
                customLabel="Name"
                disableAutoComplete={true}
            />
            
            <table>
                <tbody>
                    <tr>
                        <td><b>Email:</b></td>
                        <td className="selectable">{props.user.id}</td>
                    </tr>
                    <tr>
                        <td><b>Registration:</b></td>
                        <td className="registration selectable">
                            {registrationLink(props.user.data().registrationToken) === null ? (
                                'Completed'
                            ) : (
                                registrationLink(props.user.data().registrationToken)
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>

            <div>
                {props.myUserData.role === 'admin' ? (
                    <div>
                        <RoleRadioButtons
                            setAccountType={handleRadioChange}
                            accountType={accountType}
                        />

                        <CampaignCheckboxes
                            db={props.db}
                            initial={props.user.data().campaigns}
                            onError={setError}
                            updateSelectedCampaigns={handleSetCampaign}
                            selectAll={forceAllCampaignAccess(accountType)}
                        />
                    </div>
                ) : null}
            </div>
            
        </Card>
    )
}

export default EditUser
