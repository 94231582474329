import React, { useState } from 'react'
import './NewCampaign.css'
import InputField from '../Components/InputField'
import Card from './Card'
import firebase from "firebase";

function NewCampaign(props: {
    db: firebase.firestore.Firestore,
    reload: () => void,
}) {
    const [name, setName] = useState<string | null>(null)
    const [description, setDescription] = useState<string | null>(null)
    const [maxAssessmentAge, setMaxAssessmentAge] = useState(7)
    const [error, setError] = useState<string | null>(null)

    const handleSubmit = () => {
        if (name === null || description === null || name.length === 0 || description.length === 0) {
            setError('Please fill in all fields.')
            return
        }
        props.db.collection('campaigns').add({
            name: name,
            description: description,
            maxAssessmentAge: maxAssessmentAge
        }).then(() => {
            props.reload()
        }).catch(error => {
            setError(error.message)
        })
    }

    return (
        <Card
            title="New Campaign"
            buttons={[
                {handler: handleSubmit, delay: -1, text: "Create"}
            ]}
            error={error}
            class="newcampaign"
        >
            <InputField
                type="custom"
                onChange={setName}
                customLabel="Name"
            />
            <InputField
                type="textarea"
                onChange={setDescription}
                customLabel="Description"
            />
            <InputField
                type="number-value"
                onChange={setMaxAssessmentAge}
                customLabel="Hide assessments older than (days)"
                initial={maxAssessmentAge}
            />
        </Card>
    )
}

export default NewCampaign
