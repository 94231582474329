import React, { useEffect, useState } from 'react'
import './ConnectionDetector.css'

function ConnectionDetector() {
    const [online, setOnline] = useState(true)

    const goOnline = () => setOnline(true)
    const goOffline = () => setOnline(false)
    
    useEffect(() => {
        window.addEventListener('online', goOnline)
        window.addEventListener('offline', goOffline)
        return () => {
            window.removeEventListener('online', goOnline)
            window.removeEventListener('offline', goOffline)
        }
    }, [])

    if (online) return (<div/>)
    
    return (
        <div className="grid3x3 connectiondetector">
            <div className="text">
                You are offline.
            </div>
        </div>
    )
}

export default ConnectionDetector
