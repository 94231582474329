import React, { useState, MouseEvent } from 'react'
import './Button.css'
import Spinner from './Spinner'

const timeout = 5000

function Button(props: {
    handler?: (e: MouseEvent) => void,
    delay: number,
    text: string,
    transparent?: boolean,
    shadow?: boolean,
}) {
    const [showSpinner, setShowSpinner] = useState(false)
    const [busy, setBusy] = useState(false)

    const handleClick = (event: MouseEvent) => {
        setShowSpinner(true)
        if (busy) return
        setBusy(true)

        if (props.delay === -1) {
            props.handler && props.handler(event)
            setTimeout(() => {
                setShowSpinner(false)
                setBusy(false)
            }, timeout);
            return
        }
        
        setTimeout(() => {
            setShowSpinner(false)
            setBusy(false)
            props.handler && props.handler(event)
        }, props.delay)
    }
    
    const spinner = (<Spinner/>)
    // const content = showSpinner ? spinner : props.text
    
    return (
        <button
            onClick={handleClick}
            style={{backgroundColor: props.transparent ? 'transparent' : ''}}
            className={props.shadow ? 'shadow' : undefined}
        >
            <div className="grid3x3" style={{height: 0, opacity: showSpinner ? 1 : 0}}>
                {spinner}
            </div>
            <div className="grid3x3" style={{opacity: showSpinner ? 0 : 1}}>
                <div className="a5">
                    {props.text}
                </div>
            </div>
        </button>
    )
}

export default Button
