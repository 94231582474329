import React, { ReactElement, useState } from 'react'
import './Collapsible.css'

function Collapsible(props: {
    title?: string,
    type?: 'huge' | 'image',
    opened?: boolean,
    children: ReactElement | ReactElement[],
}) {
    const [open, setOpen] = useState(!!props.opened)

    if (props.type === 'image') return (
        <div className="collapsible">
            {/* <em className="underline">{open ? 'Hide image' : 'Show image'}</em> */}
            <div className="children">{props.children}</div>
        </div>
    )

    if (props.type === 'huge') return (
        <div className="collapsible">
            <h1 className="huge" onClick={() => setOpen(!open)}>{props.title}</h1>
            {open ? (
                <div className="children">{props.children}</div>
            ) : null}
        </div>
    )

    return (
        <div className="collapsible">
            <h1 onClick={() => setOpen(!open)}>{props.title}</h1>
            {open ? (
                <div className="children">{props.children}</div>
            ) : null}
        </div>
    )
}

export default Collapsible
