import React from 'react'
import mask from '../../Assets/landingpage/face-mask.svg'
import distancing from '../../Assets/landingpage/social-distancing.svg'
import assessment from '../../Assets/landingpage/protection.svg'
import Footer from "./Footer";
import {TabsEnum} from "./Tabs";
import getText from "../../Utils/Translations";

function Covid19(props: {
    chooseTab: (tab: TabsEnum) => void,
}) {
    return (
        <div>
            <div className="centre">
                <h1 className="xxhuge centered" dangerouslySetInnerHTML={getText("covidTitle")}/>
            </div>
            <div className="bigheight"/>

            <div className="black-accent">
                <div className="medheight"/>
                <div className="centre side-by-side">
                    <div className="left-half grid1x3">
                        <div className="centered grid3x3">
                            <img src={mask} alt="PPE" className="covid-icon"/>
                        </div>
                    </div>
                    <div className="right-half grid3x3">
                        <div>
                            <h1 className="xhuge" dangerouslySetInnerHTML={getText("covid1Title")}/>
                            <span dangerouslySetInnerHTML={getText("covid1Text")}/>
                        </div>
                    </div>
                </div>
                <div className="centre side-by-side">
                    <div className="left-half grid1x3">
                        <div className="centered grid3x3">
                            <img src={distancing} alt="Social Distancing" className="covid-icon"/>
                        </div>
                    </div>
                    <div className="right-half grid3x3">
                        <div>
                            <h1 className="xhuge" dangerouslySetInnerHTML={getText("covid2Title")}/>
                            <span dangerouslySetInnerHTML={getText("covid2Text")}/>
                        </div>
                    </div>
                </div>
                <div className="centre side-by-side">
                    <div className="left-half grid1x3">
                        <div className="centered grid3x3">
                            <img src={assessment} alt="Risk Assessment" className="covid-icon"/>
                        </div>
                    </div>
                    <div className="right-half grid3x3">
                        <div>
                            <h1 className="xhuge" dangerouslySetInnerHTML={getText("covid3Title")}/>
                            <span dangerouslySetInnerHTML={getText("covid3Text")}/>
                        </div>
                    </div>
                </div>
                <div className="medheight"/>
            </div>

            <div className="accented">
                <div className="centre centered">
                    <h1 dangerouslySetInnerHTML={getText("covidText")}/>
                </div>
            </div>

            <Footer chooseTab={props.chooseTab}/>
        </div>
    )
}

export default Covid19