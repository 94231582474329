import React from 'react'
import Footer from "./Footer";
import {TabsEnum} from "./Tabs";
import lock from "../../Assets/landingpage/lock.svg";
import security from "../../Assets/landingpage/security.svg";
import account from "../../Assets/landingpage/account.svg";
import document from "../../Assets/landingpage/document.svg";
import getText from "../../Utils/Translations";

function Security(props: {
    chooseTab: (tab: TabsEnum) => void,
}) {
    return (
        <div>
            <div className="centre">
                <h1 className="xxhuge centered" dangerouslySetInnerHTML={getText("securityTitle")}/>
            </div>

            <div className="centre">
                <div className=" side-by-side">
                    <div className="left-half">
                        <div className="centered">
                            <img className="home-icon" src={lock} alt="Evergiving & Formunauts"/>
                        </div>
                        <p className="centered" dangerouslySetInnerHTML={getText("securityText1")}/>
                    </div>
                    <div className="right-half">
                        <div className="centered">
                            <img className="home-icon" src={security} alt="security"/>
                        </div>
                        <p className="centered" dangerouslySetInnerHTML={getText("securityText2")}/>
                    </div>
                </div>
                <div className="centre side-by-side">
                    <div className="left-half">
                        <div className="centered">
                            <img className="home-icon" src={account} alt="Account"/>
                        </div>
                        <p className="centered" dangerouslySetInnerHTML={getText("securityText3")}/>
                    </div>
                    <div className="right-half">
                        <div className="centered">
                            <img className="home-icon" src={document} alt="Document"/>
                        </div>
                        <p className="centered" dangerouslySetInnerHTML={getText("securityText4")}/>
                    </div>
                </div>
            </div>


            <Footer chooseTab={props.chooseTab}/>
        </div>
    )
}

export default Security