import React, { useEffect, useState } from 'react'
import './ManageUsers.css'
import * as firebase from "firebase/app"
import Spinner from '../Components/Spinner'
import Edit from '../Assets/edit.svg'
import EditUser from './EditUser'
import Card from './Card'

function ManageUsers(props: {
    newUser: () => void
    db: firebase.firestore.Firestore
    myUserData: firebase.firestore.DocumentData
}) {
    const [users, setUsers] = useState<firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]
    | null>(null)
    const [campaigns, setCampaigns] = useState<firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]
    | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [editUser, setEditUser] = useState<firebase.firestore.DocumentData | null>(null)

    useEffect(() => {
        let mounted = true
        props.db.collection('users').orderBy('role').get().then(userSnapshot => {
            if (mounted) setUsers(userSnapshot.docs)
        }).catch(error => {
            if (mounted) setError(error.message)
        })
        props.db.collection('campaigns').orderBy('name', 'asc').get().then(campaignSnapshot => {
            if (mounted) setCampaigns(campaignSnapshot.docs)
        }).catch(error => {
            if (mounted) setError(error.message)
        })
        return () => { mounted = false }
    }, [props.db])

    const back = () => {
        setEditUser(null)
        setUsers(null)
        props.db.collection('users').orderBy('role').get().then(querySnapshot => {
            setUsers(querySnapshot.docs)
        }).catch(error => {
            setError(error.message)
        })
    }

    if (editUser !== null) return (
        <EditUser
            user={editUser}
            db={props.db}
            back={back}
            myUserData={props.myUserData}
        />
    )

    const handleUserClick = (user: firebase.firestore.DocumentData) => {
        setEditUser(user)
    }

    const countCampaigns = (user: firebase.firestore.DocumentData) => {
        if (campaigns === null) return 'loading...'
        if (user.data().campaigns.length === 1) {
            const id = user.data().campaigns[0]
            return campaigns.find(c => c.id === id)?.data().name
        } else {
            return `${user.data().campaigns.length} Campaigns`
        }
    }

    return (
        <Card
            title="Manage Users"
            buttons={[
                {handler: props.newUser, delay: 500, text: "Create New User"}
            ]}
            class="manageusers"
        >
            {(() => {
                if (error !== null) return (<div className="grid3x3"><span>{error}</span></div>)
                if (users === null) return (<div className="grid3x3"><Spinner/></div>)
                return (
                    <table cellSpacing={0}>
                        <thead>
                            <tr>
                                <th/>
                                <th>Name</th>
                                <th>Account type</th>
                                <th>Registered</th>
                                <th><span className="block">Has access to</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user, index) => (
                                <tr key={index} onClick={() => handleUserClick(user)}>
                                    <td className="edit"><img src={Edit} alt="edit"/></td>
                                    <td>{user.data().name ? user.data().name : user.id}</td>
                                    <td className="capitalize">{user.data().role}</td>
                                    <td>{user.data().registrationToken === null ? 'Yes' : 'No'}</td>
                                    <td>{countCampaigns(user)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )
            })()}
        </Card>
    )
}

export default ManageUsers
