import React from 'react'

function InputField(props: {
    type: 
        'custom' |
        'custom-value' |
        'textarea' |
        'textarea-value' |
        'email' |
        'current-password' |
        'new-password' |
        'confirm-password' |
        'number-value',
    onChange: (value: any) => void,
    autoFocus?: boolean,
    disableAutoComplete?: boolean,
    customLabel?: string,
    optional?: boolean,
    initial?: any,
}) {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.value)
    }

    if (props.type === 'custom') {
        return (
            <div>
                {props.customLabel ? (
                    <label className="input" htmlFor={props.customLabel}>{props.customLabel}</label>
                ) : null}
                <input
                    className="input"
                    type="text"
                    name="custom"
                    id={props.customLabel}
                    autoComplete="off"
                    onChange={handleChange}
                    data-testid="custom-field"
                    autoFocus={!!props.autoFocus}
                    required={!props.optional}
                />
            </div>
        )
    }

    if (props.type === 'custom-value') {
        return (
            <div>
                <label className="input" htmlFor={props.customLabel}>{props.customLabel ? props.customLabel : "Input"}</label>
                <input
                    className="input"
                    type="text"
                    name="custom"
                    id={props.customLabel}
                    autoComplete="off"
                    onChange={handleChange}
                    data-testid="custom-field"
                    autoFocus={!!props.autoFocus}
                    required={!props.optional}
                    value={props.initial !== undefined ? props.initial : 'loading...'}
                />
            </div>
        )
    }

    if (props.type === 'textarea') {
        return (
            <div>
                <label className="input" htmlFor="textarea">{props.customLabel ? props.customLabel : "Text"}</label>
                <textarea
                    className="input"
                    id="textarea"
                    name="textarea"
                    autoComplete="off"
                    onChange={(e) => props.onChange(e.target.value)}
                    data-testid="textarea-field"
                    autoFocus={!!props.autoFocus}
                    required={!props.optional}
                    // value={props.initial ? props.initial : ''}
                    rows={6}
                    style={{width: "100%"}}
                />
            </div>
        )
    }

    if (props.type === 'textarea-value') {
        return (
            <div>
                <label className="input" htmlFor="textarea">{props.customLabel ? props.customLabel : "Text"}</label>
                <textarea
                    className="input"
                    id="textarea"
                    name="textarea"
                    autoComplete="off"
                    onChange={(e) => props.onChange(e.target.value)}
                    data-testid="textarea-field"
                    autoFocus={!!props.autoFocus}
                    required={!props.optional}
                    value={props.initial !== undefined ? props.initial : 'loading...'}
                    rows={6}
                    style={{width: "100%"}}
                />
            </div>
        )
    }

    if (props.type === 'email') {
        return (
            <div>
                <label className="input" htmlFor="email">Email</label>
                <input
                    className="input"
                    id="email"
                    type="email"
                    name="email"
                    autoComplete={props.disableAutoComplete ? "off" : "email"}
                    onChange={handleChange}
                    data-testid="email-field"
                    autoFocus={!!props.autoFocus}
                    required={!props.optional}
                    // value={props.initial ? props.initial : ''}
                />
            </div>
        )
    }

    if (props.type === 'current-password') {
        return (
            <div>
                <label className="input" htmlFor="password">Password</label>
                <input
                    className="input"
                    id="password"
                    type="password"
                    name="current-password"
                    autoComplete={props.disableAutoComplete ? "off" : "current-password"}
                    onChange={handleChange}
                    data-testid="current-password-field"
                    required={!props.optional}
                    // value={props.initial ? props.initial : ''}
                />
            </div>
        )
    }

    if (props.type === 'new-password') {
        return (
            <div>
                <label className="input" htmlFor="newpassword">New Password</label>
                <input
                    className="input"
                    id="newpassword"
                    type="password"
                    name="new-password"
                    autoComplete={props.disableAutoComplete ? "off" : "new-password"}
                    onChange={handleChange}
                    data-testid="new-password-field"
                    required={!props.optional}
                    // value={props.initial ? props.initial : ''}
                />
            </div>
        )
    }

    if (props.type === 'confirm-password') {
        return (
            <div>
                <label className="input" htmlFor="confirmpassword">Confirm Password</label>
                <input
                    className="input"
                    id="confirmpassword"
                    type="password"
                    name="new-password"
                    autoComplete={props.disableAutoComplete ? "off" : "new-password"}
                    onChange={handleChange}
                    data-testid="confirm-password-field"
                    required={!props.optional}
                    // value={props.initial ? props.initial : ''}
                />
            </div>
        )
    }

    if (props.type === 'number-value') {
        return (
            <div>
                <label className="input" htmlFor="confirmpassword">{props.customLabel ? props.customLabel : "Number"}</label>
                <input
                    className="input"
                    id="number"
                    type="number"
                    name="number"
                    onChange={handleChange}
                    data-testid="number-input-field"
                    required={!props.optional}
                    value={props.initial ? props.initial : 0}
                />
            </div>
        )
    }

    return null
    
}

export default InputField
