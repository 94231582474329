import React, { ReactElement } from 'react'
import './Card.css'
import Button from '../Components/Button'
import TitleWithBack from '../Components/TitleWithBack'
import ErrorMessage from '../Components/ErrorMessage'
import ConfirmButton from '../Components/ConfirmButton'
import Spinner from '../Components/Spinner'

function Card(props: {
    title: string,
    back?: () => void,
    error?: string | null,
    description?: string,
    children: ReactElement | ReactElement[],
    buttons: ({text: string, delay: number, handler?: () => void, confirm?: boolean} | null)[],
    class?: string,
    loading?: boolean,
    onClickTitle?: () => void
}) {
    return (
        <div className={`card ${props.class ? props.class : ''}`}>
            <div className="top" onClick={props.onClickTitle}>
                {props.back ? (
                    <TitleWithBack onClick={props.back} text={props.title}/>
                ) : (
                    <h1>{props.title}</h1>
                )}
                {props.description ? (
                    <p className="description">{props.description}</p>
                ) : null}
            </div>
            <div className="solid">
                <div className="middle">
                    {props.loading ? (<Spinner/>) : props.children}
                </div>
            </div>
            <ErrorMessage message={props.error ? props.error : null}/>
            <div className="centered">
                {props.buttons.filter(button => button !== null).map((button, index) => {
                    if (button!.confirm) { return (
                        <ConfirmButton
                            text={button!.text}
                            delay={button!.delay}
                            handler={button!.handler ? button!.handler : () => {}}
                            key={index + (new Date()).getTime()}
                        />
                    )} else { return (
                        <Button
                            text={button!.text}
                            delay={button!.delay}
                            handler={button!.handler ? button!.handler : () => {}}
                            key={index + (new Date()).getTime()}
                        />
                    )}
                })}
            </div>
        </div>
    )
}

export default Card
