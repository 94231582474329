import React, { useState, useEffect } from 'react'
import './RegisterUser.css'
import Spinner from '../Components/Spinner'
import * as firebase from "firebase/app"
import Button from '../Components/Button'
import { cleanURL } from '../Utils/PureFunctions'

function RegisterUser(props: {
    token: string,
    db: firebase.firestore.Firestore
}) {
    const [email, setEmail] = useState<string | null>(null)
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [buttonKey, setButtonKey] = useState(0)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [invalid, setInvalid] = useState(false)
    const [showLogin, setShowLogin] = useState(false)

    const resetButton = () => {
        setButtonKey(buttonKey + 1)
    }

    useEffect(() => {
        let mounted = true
        props.db.collection('users').where('registrationToken', '==', props.token).get().then(querySnapshot => {
            if (!mounted) return
            if (querySnapshot.docs.length === 0) setInvalid(true)
            querySnapshot.forEach(doc => {
                setEmail(doc.id)
            })
        })
        return () => {mounted = false}
    }, [props.db, props.token])

    if (invalid) return (
        <div className="grid3x3 fullscreen">
            <div>
                <h1>Sorry, this token has already been redeemed or it is invalid.</h1>
                <div className="grid3x3">
                    <Button handler={cleanURL} delay={500} text='Go To Login'/>
                </div>
            </div>
        </div>
    )

    if (email === null) return (
        <div className="grid3x3 fullscreen">
            <Spinner/>
        </div>
    )

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        if (newPassword !== confirmPassword) {
            setErrorMessage('You have entered two different passwords, please double check this and try again.')
            resetButton()
            return
        }
        try {
            firebase.auth().createUserWithEmailAndPassword(email, newPassword).then(() => {
                cleanURL()
            }).catch((error) => {
                if (error.code === 'auth/email-already-in-use') {
                    setErrorMessage('This user has already existed in the database before, please go back to the login page and login, or use the forgot password feature to reset your password.')
                    setShowLogin(true)
                } else {
                    setErrorMessage(error.message)
                    console.log(error)
                    resetButton()
                }
            })
        } catch (error) {
            setErrorMessage('Something went wrong, try logging in again.')
            console.log(error)
            resetButton()
        }
    }

    const handleInvaidSubmit = () => {
        resetButton()
    }

    const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(event.target.value)
    }

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value)
    }

    return (
        <div className="grid3x3 fullscreen">
            <form onSubmit={handleSubmit} onInvalid={handleInvaidSubmit}>
                <div className="registeruser">
                    <h1>Welcome {email}.</h1>
                    <h1>Please complete your registration by choosing a strong password.</h1>

                    <label className="input" htmlFor="newpassword">New Password</label>
                    <input
                        className="input"
                        id="newpassword"
                        type="password"
                        name="new-password"
                        autoComplete="new-password"
                        onChange={handleNewPasswordChange}
                        data-testid="new-password-field"
                        autoFocus
                        required
                    />

                    <label className="input" htmlFor="confirmpassword">Confirm Password</label>
                    <input
                        className="input"
                        id="confirmpassword"
                        type="password"
                        name="new-password"
                        autoComplete="new-password"
                        onChange={handleConfirmPasswordChange}
                        data-testid="confirm-password-field"
                        required
                    />

                </div>

                {errorMessage !== null ? (
                    <div className="grid3x3">
                        <div className="registeruser-error centered">{errorMessage}</div>
                    </div>
                ) : null}

                {!showLogin ? (
                    <div className="grid3x3">
                        <Button text="Register" delay={-1} key={buttonKey}/>
                    </div>
                ) : (
                    <div className="grid3x3">
                        <Button text="Back to login" delay={-1} handler={cleanURL}/>
                    </div>
                )}
            </form>
        </div>
    )
}

export default RegisterUser
