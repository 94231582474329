import React from 'react'
import thomas from '../../Assets/landingpage/thomas.jpg'
// import richard from '../../Assets/landingpage/richard.jpg'
import Footer from './Footer'
import {TabsEnum} from "./Tabs";
import aboutImage from '../../Assets/landingpage/about-image.jpg'
import getText from "../../Utils/Translations";

function About(props: {
    chooseTab: (tab: TabsEnum) => void,
}) {
    return (
        <div>
            <img src={aboutImage} alt="about" className="about-image"/>
            {/*<div className="centre">*/}
            {/*    <h1 className="xxhuge centered white-text">Passion. Experi&shy;ence. Diligence.</h1>*/}
            {/*</div>*/}


            <div className="">
                <div className="centre side-by-side">
                    <div className="left-half grid3x3 centered">
                        <h1 className="xxhuge" dangerouslySetInnerHTML={getText('missionTitle')}/>
                    </div>
                    <div className="right-half grid3x3">
                        <div dangerouslySetInnerHTML={getText('missionText')}/>
                    </div>
                </div>
            </div>

            <div className="accented">
                <div className="centre">
                    <h1 className="xxhuge centered" dangerouslySetInnerHTML={getText('values')}/>
                    <div className="side-by-side">
                        <div className="left-half ">
                            <div className="card">
                                <h1 dangerouslySetInnerHTML={getText('value1Title')}/>
                                <span dangerouslySetInnerHTML={getText("value1Text")}/>
                            </div>
                        </div>
                        <div className="right-half ">
                            <div className="card">
                                <h1 dangerouslySetInnerHTML={getText("value2Title")}/>
                                <span dangerouslySetInnerHTML={getText("value2Text")}/>
                            </div>
                        </div>
                    </div>
                    <div className="side-by-side">
                        <div className="left-half ">
                            <div className="card">
                                <h1 dangerouslySetInnerHTML={getText("value3Title")}/>
                                <span dangerouslySetInnerHTML={getText("value3Text")}/>
                            </div>
                        </div>
                        <div className="right-half ">
                            <div className="card">
                                <h1 dangerouslySetInnerHTML={getText("value4Title")}/>
                                <span dangerouslySetInnerHTML={getText("value4Text")}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="medheight"/>
            </div>

            <div className="black-accent">
                <div className="medheight"/>
                <div className="centre side-by-side">
                    <div className="left-half grid3x3">
                        <img className="profile-pic" src={thomas} alt="Thomas Lavery"/>
                    </div>
                    <div className="right-half grid3x3">
                        <div>
                            <b dangerouslySetInnerHTML={getText("thomasBioTitle")}/>
                            <br/>
                            <span dangerouslySetInnerHTML={getText("thomasBio")}/>
                        </div>
                    </div>
                </div>

                {/* <div className="centre side-by-side">
                    <div className="left-half grid3x3">
                        <img className="profile-pic" src={richard} alt="Richard Yorke"/>
                    </div>
                    <div className="right-half grid3x3">
                        <div>
                            <b dangerouslySetInnerHTML={getText("richardBioTitle")}/>
                            <br/>
                            <span dangerouslySetInnerHTML={getText("richardBio")}/>
                        </div>
                    </div>
                </div> */}

                <div className="medheight"/>
            </div>

            <div className="accented">
                <div className="centre">
                    <h1 className="centered" dangerouslySetInnerHTML={getText("aboutText")}/>
                </div>
            </div>

            <Footer chooseTab={props.chooseTab}/>
        </div>
    )
}

export default About