import React from 'react'
import {TabsEnum} from "./Tabs";
import Footer from "./Footer";
import email from '../../Assets/landingpage/email.svg'
import getText from "../../Utils/Translations";

function Contact(props: {
    chooseTab: (tab: TabsEnum) => void,
}) {
    return (
        <div>
            <div className="centre centered">
                <h1 className="xxhuge" dangerouslySetInnerHTML={getText("contactTitle")}/>
            </div>
            <div className="medheight"/>

            <div className="accented centered">
                <div className="bigheight"/>
                <img src={email} alt="Email" width={100}/>
                <div className="centre" dangerouslySetInnerHTML={getText("contactText")}/>
                <div className="bigheight"/>
            </div>

            <Footer chooseTab={props.chooseTab}/>
        </div>
    )
}

export default Contact