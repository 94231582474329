import React from 'react'
import {TabsEnum} from "./Tabs";
import Footer from "./Footer";
import getText from "../../Utils/Translations";
import Button from '../../Components/Button';

function Complaints(props: {
    chooseTab: (tab: TabsEnum) => void,
}) {
    return (
        <div>
            <div className="centre centered">
                <h1 className="xxhuge" dangerouslySetInnerHTML={getText("complaintsTitle")}/>
            </div>
            <div className="medheight"/>
            <div className="centre centered card">
                <p>{getText("complaintsText").__html}</p>
                <Button delay={500} text={getText("complaintsButtonText").__html} handler={() => {
                    window.location.href = "AspectFS%20-%20Complaints%20Procedure.docx"
                }}/>
            </div>

            <div className="medheight"/>

            <Footer chooseTab={props.chooseTab}/>
        </div>
    )
}

export default Complaints