import * as firebase from "firebase/app"
import {forceAllCampaignAccess} from "./Privileges"
import resizeImage from "./ResizeImage";

const getCampaignById = (
    id: string | null,
    allCampaigns: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[] | null
) => {
    if (id === null || allCampaigns === null) return {name: 'Loading...', description: 'Loading...'}
    return allCampaigns.filter(c => c.id === id)[0].data()
}

// if changed, remember to also change style.css
const isMobile = () => window.innerWidth < 850

const getRootURL = () => {
    return window.location.href.split('/')[0] + '//' + window.location.href.split('/')[2]
}

const registrationLink = (token: string) => {
    if (token === null) {
        return null
    } else {
        return getRootURL() + '/register/' + token
    }
}

const cleanURL = () => {
    window.location.href = getRootURL()
}

// const goToDonationForm = (id: string | null) => {
//     window.location.href = getRootURL() + '/form/' + id
// }

const goToLandingPage = () => {
    window.location.href = getRootURL()
}

const generateToken = () => {
    const tokenLength = 15
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < tokenLength; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const giveAdminAllCampaignAccess = (
    db: firebase.firestore.Firestore
) => {
    console.log('Giving admin access to all campaigns')
    return new Promise((res, rej) => {
        db.collection('campaigns').orderBy('name', 'asc').get().then(snapshot => {
            const currentUser = firebase.auth().currentUser
            if (currentUser === null) {
                rej({ message: 'No user is authenticated.' })
                return
            }
            if (currentUser.email === null) {
                rej({ message: 'User has no email field.' })
                return
            }
            const allCampaigns = snapshot.docs.map(c => c.id)
            db.collection('users').doc(currentUser.email).update({
                campaigns: allCampaigns
            }).then(res).catch(error => rej(error))

        }).catch(error => rej(error))
    })
}

const getMyUserDocumentAndCampaignsAndFixCampaignAccess = (db: firebase.firestore.Firestore) => {
    return new Promise((res, rej) => {
        const currentUser = firebase.auth().currentUser
        if (currentUser === null) {
            rej({ message: 'No user is authenticated.' })
            return
        }
        if (currentUser.email === null) {
            rej({ message: 'User has no email field.' })
            return
        }
        db.collection('users').doc(currentUser.email).get().then(userDoc => {
            if (!userDoc.exists) {
                rej({ message: 'User document does not exist.' })
                return
            }
            db.collection('campaigns').orderBy('name', 'asc').get().then(campaignSnapshot => {
                const userData = userDoc.data()
                if (userData === undefined) {
                    rej({ message: 'User document data is undefined.'})
                    return
                }
                if (
                    forceAllCampaignAccess(userData.role) &&
                    campaignSnapshot.docs.length !== userData.campaigns.length
                ) {
                    giveAdminAllCampaignAccess(db).then(() => {
                        userData.campaigns = campaignSnapshot.docs.map(c => c.id)
                        res({userData, campaignSnapshot})
                    }).catch(error => rej(error))
                } else {
                    res({userData, campaignSnapshot})
                }
            }).catch(error => rej(error))
        }).catch(error => rej(error))
    })
}

const nullifyRegistrationToken = (db: firebase.firestore.Firestore) => {
    return new Promise((res, rej) => {
        const currentUser = firebase.auth().currentUser
        if (currentUser === null) {
            rej('No user is authenticated.')
            return
        }
        if (currentUser.email === null) {
            rej('User has no email field.')
            return
        }
        db.collection('users').doc(currentUser.email).update({
            registrationToken: null
        }).then(res).catch(error => rej(error))
    })
}

const searchAddress = (text: string) => {
    return new Promise((resolve, reject) => {
        firebase.functions().httpsCallable('searchAddress')(text).then(result => {
            resolve(result.data)
        }).catch(error => {
            reject(error)
        })
    })
}

const placeDetails = (id: string) => {
    return new Promise((resolve, reject) => {
        firebase.functions().httpsCallable('placeDetails')(id).then(result => {
            resolve(result.data)
        }).catch(error => {
            reject(error)
        })
    })
}

let timeoutID: number | null = null
const debounce = <F extends (...params: any[]) => void>(fn: F, delay: number) => {
    return function(this: any, ...args: any[]) {
        clearTimeout(timeoutID!)
        timeoutID = window.setTimeout(() => fn.apply(this, args), delay)
    } as F
}

const uploadToCloudStorage = (file: File, folder: string, filename: string) => {
    return new Promise<{url: string,filename: string}>((resolve, reject) => {
        if (file.size > 10 * 1024 * 1024) {
            reject({message: 'File size limit is 10MB'})
            return
        }
        const storageRef = firebase.storage().ref()
        const url = folder + '/' + filename
        const profileRef = storageRef.child(url)
        const uploadTask = profileRef.put(file)
        // https://firebase.google.com/docs/storage/web/upload-files#monitor_upload_progress
        uploadTask.on('state_changed',
            () => {},
            (error) => reject(error),
            () => uploadTask.snapshot.ref.getDownloadURL().then((url) => {
                resolve({url: url, filename: filename})
            })
        );
    })
}

const uploadImage = (
    folder: 'profiles' | 'campaigns' | 'assessments',
    filename: string,
    startLoading: () => void,
    maxSize: number
) => {
    return new Promise<{url: string,filename: string}>((resolve, reject) => {
        const input = document.createElement('input')
        input.style.display = 'none'
        input.type = 'file'
        input.accept = 'image/png, image/jpeg'
        input.addEventListener('change', (event: Event) => {
            const [file] = (event.target as any).files as File[]
            if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
                reject({message: 'Only JPG or PNG files are allowed for Risk Assessment documents.'})
                return
            }
            startLoading()
            resizeImage({
                file: file,
                maxSize: maxSize
            }).then(function (resizedImage) {
                uploadToCloudStorage(resizedImage, folder, filename).then(resolve).catch(reject)
            }).catch(function (err) {
                reject(err);
            })
        })
        document.body.appendChild(input)
        input.click()
        document.body.removeChild(input)
    })
}

const uploadDocument = (
    folder: 'documents',
    startLoading: () => void
) => {
    return new Promise<{url: string,filename: string}>((resolve, reject) => {
        const input = document.createElement('input')
        input.style.display = 'none'
        input.type = 'file'
        input.accept = '*'
        input.addEventListener('change', (event: Event) => {
            const [file] = (event.target as any).files as File[]
            startLoading()
            uploadToCloudStorage(file, folder, file.name).then(resolve).catch(reject)
        })
        document.body.appendChild(input)
        input.click()
        document.body.removeChild(input)
    })
}

const upload = (
    folder: 'profiles' | 'campaigns' | 'assessments' | 'documents',
    filename: string,
    startLoading: () => void,
    maxSize: number
) => {
    if (folder === 'profiles' || folder === 'campaigns' || folder === 'assessments') {
        return uploadImage(folder, filename, startLoading, maxSize)
    } else {
        return uploadDocument(folder, startLoading)
    }
}

const download = (
    folder: 'profiles' | 'campaigns' | 'assessments',
    filename: string,
) => {
    return new Promise<string>((resolve, reject) => {
        const storageRef = firebase.storage().ref()
        const url = folder + '/' + filename
        const profileRef = storageRef.child(url)
        profileRef.getDownloadURL().then(resolve).catch(reject)
    })
}

const getDateFormat = (timestamp: number) => {
    const now = (new Date()).getTime()
    const msInAMinute = 1000 * 60
    const msInAnHour = msInAMinute * 60
    const msInADay = msInAnHour * 24

    if (now - timestamp < msInAnHour) {
        const rounded = Math.round((now - timestamp) / msInAMinute)
        if (rounded === 0) return 'Just now'
        if (rounded === 1) return '1 minute ago'
        return `${rounded} minutes ago`
    }

    if (now - timestamp < msInADay) {
        const rounded = Math.round((now - timestamp) / msInAnHour)
        if (rounded === 1) return '1 hour ago'
        return `${rounded} hours ago`
    }

    return `${(new Date(timestamp)).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    })}`
}

export {
    getCampaignById,
    isMobile,
    registrationLink,
    cleanURL,
    // goToDonationForm,
    goToLandingPage,
    generateToken,
    getMyUserDocumentAndCampaignsAndFixCampaignAccess,
    giveAdminAllCampaignAccess,
    nullifyRegistrationToken,
    searchAddress,
    placeDetails,
    debounce,
    upload,
    download,
    getDateFormat,
}