import React from 'react'

function Email(props: {
    black?: boolean
}) {
    return (
        <a className={props.black ? "black-text" : "text-accented"} href="mailto:info@aspectfs.co.uk">info@aspectfs.co.uk</a>
    )
}

export default Email