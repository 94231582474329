import React from 'react'
import {Tabs, TabsEnum} from "./Tabs";
import getText from "../../Utils/Translations";

function TabList(props: {
    chooseTab: (tab: TabsEnum) => void,
}) {
    return (
        <div className="tablist">
            {Tabs.map(tab => (
                <div
                    className="tablist-item"
                    onClick={() => props.chooseTab(tab.id)}
                    key={tab.textID}
                    dangerouslySetInnerHTML={getText(tab.textID)}
                />
            ))}
        </div>
    )
}

export default TabList