import React from 'react'

function RoleRadioButtons(props: {
    setAccountType: (role: string) => void,
    accountType: string | null
}) {
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setAccountType(event.target.value)
    }

    return (
        <div className="radiogroup">
            <span className="radio">
                <input
                    onChange={handleRadioChange}
                    className="radio"
                    type="radio"
                    name="type"
                    id="admin"
                    value="admin"
                    checked={props.accountType === 'admin'}
                    required
                />
                <label className="radio" htmlFor="admin">Admin</label>
            </span>

            <span className="radio">
                <input
                    onChange={handleRadioChange}
                    className="radio"
                    type="radio"
                    name="type"
                    id="client"
                    value="client"
                    checked={props.accountType === 'client'}
                    required
                />
                <label className="radio" htmlFor="client">Client</label>
            </span>

            <span className="radio">
                <input
                    onChange={handleRadioChange}
                    className="radio"
                    type="radio"
                    name="type"
                    id="fundraiser"
                    value="fundraiser"
                    checked={props.accountType === 'fundraiser'}
                    required
                />
                <label className="radio" htmlFor="fundraiser">Fundraiser</label>
            </span>
        </div>
    )
}

export default RoleRadioButtons
