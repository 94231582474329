import React from 'react'
import logo from '../../Assets/logo-white.png'
import {Tabs, TabsEnum} from "./Tabs";
import Email from "./Email";
import getText from "../../Utils/Translations";

function Footer(props: {
    chooseTab: (tab: TabsEnum) => void,
}) {
    const changeLanguage = (language: string) => {
        localStorage.setItem('language', language)
        document.location.reload()
    }

    return (
        <div className="black-accent">
            <div className="bigheight"/>
            <div className="centre side-by-side">
                <div className="left-half grid3x3 centered">
                    <div>
                        <img src={logo} alt="Logo" width="200"/>
                        <p dangerouslySetInnerHTML={getText("foot1")}/>
                        <p dangerouslySetInnerHTML={getText("foot2")}/>
                        <Email/>
                        <br/>
                        <br/>
                        <span className="link" onClick={() => changeLanguage('en')}>English</span> |
                        <span className="link" onClick={() => changeLanguage('es')}>Spanish</span>
                    </div>
                </div>
                <div className="right-half">
                    <div>
                        {Tabs.map(tab => (
                            <div
                                className="footer-tablist-item"
                                onClick={() => props.chooseTab(tab.id)}
                                key={tab.textID}
                                dangerouslySetInnerHTML={getText(tab.textID)}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className="bigheight"/>
        </div>
    )
}

export default Footer