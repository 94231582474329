import React, { useState } from 'react'

function CustomRadio(props: {
    name: string,
    values: {name: string, value: string}[],
    otherType: string | null,
    onChange: (value: string) => void,
}) {
    const [other, setOther] = useState<boolean>(false)
    
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.value)
        setOther(false)
    }
    
    return (
        <div>
            <div className="radiogroup">
                {props.values.map((value, index) => (
                    <span className="radio" key={index}>
                        <input
                            onChange={handleRadioChange}
                            className="radio"
                            type="radio"
                            name={props.name}
                            id={props.name + value.name}
                            value={value.value}
                            required
                        />
                        <label className="radio" htmlFor={props.name + value.name}>{value.name}</label>
                    </span>
                ))}

                {props.otherType !== null ? (
                    <span className="radio">
                        <input
                            onChange={() => setOther(true)}
                            className="radio"
                            type="radio"
                            name={props.name}
                            id={props.name + "other"}
                            required
                        />
                        <label className="radio" htmlFor={props.name + "other"}>Other</label>
                    </span>
                ) : null}

            </div>

            {other ? (
                <input
                    className="input"
                    type={props.otherType ? props.otherType : 'text'}
                    name="custom"
                    autoComplete="off"
                    onChange={(value) => props.onChange(value.target.value)}
                    autoFocus={true}
                    required={true}
                />
            ) : null}
        </div>
    )
}

export default CustomRadio
