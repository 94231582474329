import React, { useState, useEffect } from 'react'
import './ProfilePicture.css'
import { download } from '../Utils/PureFunctions'

function ProfilePicture(props: {
    email: string,
    name: string,
    onClick?: () => void,
}) {
    const [profilePic, setProfilePic] = useState<string | null>(null)

    useEffect(() => {
        download('profiles', props.email).then(url => {
            setProfilePic(url)
        }).catch(error => {
            console.log(error)
        })
    }, [props.email])

    return <div className="grid3x3" onClick={props.onClick}>
        {profilePic === null ? (
            <div className="accountcircle grid3x3">
                <div className="accountletter">{props.name ? props.name.substr(0,1) : props.email.substr(0,1)}</div>
            </div>
        ) : (
            <img className="profile" src={profilePic} alt="profile pic"/>
        )}
    </div>
}

export default ProfilePicture
