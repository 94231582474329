import React from 'react'

function ErrorMessage(props: {
    message: string | null
}) {
    if (props.message === null) {
        return null
    } else {
        return (<p className="error centered">{props.message}</p>)
    }
}

export default ErrorMessage
