import React, { useEffect, useState } from 'react'
import * as firebase from "firebase/app"
import Button from '../Components/Button'
import Spinner from '../Components/Spinner'
import NewCampaign from '../Cards/NewCampaign'
import ChangePassword from '../Cards/ChangePassword'
import MyAccount from '../Cards/MyAccount'
import ManageUsers from '../Cards/ManageUsers'
import NewUser from '../Cards/NewUser'
import './Home.css'
import Logo from '../Assets/logo.png'
import Hamburger from '../Assets/hamburger.svg'
import Campaign from '../Cards/Campaign'
import {
    getCampaignById,
    isMobile,
    getMyUserDocumentAndCampaignsAndFixCampaignAccess,
    nullifyRegistrationToken
} from '../Utils/PureFunctions'
import ErrorMessage from '../Components/ErrorMessage'
import { canManageUsers, canCreateNewCampaign } from '../Utils/Privileges'


enum Pages {
    myaccount,
    newcampaign,
    changepassword,
    manageusers,
    newuser,
    campaign,
}

function Home(props: {
    db: firebase.firestore.Firestore,
    reload: () => void,
}) {
    const [error, setError] = useState<string | null>(null)
    const [userData, setUserData] = useState<firebase.firestore.DocumentData | undefined>(undefined)
    const [navOpened, setNavOpened] = useState(true)
    const [page, setPage] = useState(Pages.myaccount)
    const [selectedCampaign, setSelectedCampaign] = useState<string | null>(null)
    const [allCampaigns, setAllCampaigns] = useState<firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[] | null>(null)
    const [mainKey, setMainKey] = useState(0)

    const openNav = () => {
        setNavOpened(true)
        document.body.style.setProperty('--navOffset', '0px')
    }
    const closeNav = () => {
        setNavOpened(false)
        document.body.style.setProperty('--navOffset', '-250px')
    }
    const toggleNav = () => {
        if (navOpened) closeNav()
        else openNav()
    }

    useEffect(() => {
        const listener = () => {
            window.history.pushState(null, document.title, window.location.href)
            openNav()
        }
        window.history.pushState(null, document.title, window.location.href)
        window.addEventListener('popstate', listener)
        return () => {
            window.removeEventListener('popstate', listener)
        }
    }, [])

    // on first load, fetch the relevant access document
    useEffect(() => {
        getMyUserDocumentAndCampaignsAndFixCampaignAccess(props.db).then(({userData, campaignSnapshot}: any) => {
            // successful document fetch, save document in state (causing the page to show)
            setUserData(userData)
            setAllCampaigns(campaignSnapshot.docs)
            if (userData.campaigns.length > 0) handleCampaignClick(userData.campaigns[0])
            // if on small screens, hide the nav by default
            if (isMobile()) closeNav()
            if (userData.registrationToken !== null) {
                nullifyRegistrationToken(props.db).catch(error => {
                    setError(error.message)
                    console.log(error)
                })
            }
        }).catch((error: any) => {
            setError(error.message)
            console.log(error)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.db])
    
    const handleSignOut = () => {
        firebase.auth().signOut().then(() => {})
    }

    const handleHamburger = () => {
        toggleNav()
    }

    const handleNavClick = (clickedPage: Pages) => {
        setPage(clickedPage)
        if (isMobile()) closeNav()
        setMainKey(mainKey + 1)
    }

    const handleCampaignClick = (campaignId: string) => {
        setSelectedCampaign(campaignId)
        setPage(Pages.campaign)
        if (isMobile()) closeNav()
        setMainKey(mainKey + 1)
    }

    if (error) return (
        <div className="centered">
            <ErrorMessage
                message={error}
            />
            <Button handler={handleSignOut} delay={500} text='Back to Login'/>
        </div>
    )

    // access won't be undefined if the access document was fetched successfully
    if (userData === undefined) return (<Spinner/>)

    const currentPage = (() => {
        switch(page) {
            case Pages.myaccount: return (
                <MyAccount
                    userData={userData}
                    changePassword={() => setPage(Pages.changepassword)}
                    myCampaigns={userData.campaigns.map((id: string) => ({
                        id: id,
                        name: getCampaignById(id, allCampaigns).name
                    }))}
                    goToCampaign={(id) => handleCampaignClick(id)}
                    db={props.db}
                    reload={props.reload}
                />
            )
            case Pages.newcampaign: return (
                <NewCampaign
                    db={props.db}
                    reload={props.reload}
                />
            )
            case Pages.changepassword: return (
                <ChangePassword
                    goToMyAccount={() => setPage(Pages.myaccount)}
                />
            )
            case Pages.manageusers: return (
                <ManageUsers
                    newUser={() => setPage(Pages.newuser)}
                    db={props.db}
                    myUserData={userData}
                />
            )
            case Pages.newuser: return (
                <NewUser
                    db={props.db}
                    back={() => setPage(Pages.manageusers)}
                />
            )
            case Pages.campaign: return (
                <Campaign
                    id={selectedCampaign}
                    campaign={getCampaignById(selectedCampaign, allCampaigns)}
                    db={props.db}
                    reload={props.reload}
                    accountType={userData.role}
                    username={userData.name}
                />
            )
        }
    })()

    const myNavItems = (() => {
        const items = []
        items.push({associatedPage: Pages.myaccount, text: 'My Account'})

        if (canManageUsers(userData.role))        items.push({associatedPage: Pages.manageusers, text: 'Manage Users'})
        if (canCreateNewCampaign(userData.role))  items.push({associatedPage: Pages.newcampaign, text: 'Create New Campaign'})

        return items
    })()

    const campaignsToShow = (() => {
        if (allCampaigns === null) return []
        return userData.campaigns
    })()
    
    return (
        <div className='fullscreen home' data-testid="home">
            <div className="burgerarea grid3x3">
                <div className="burgerbutton" onClick={handleHamburger}>
                    <img src={Hamburger} alt="Hamburger" className="hamburger"/>
                </div>
            </div>
            <div className="titlebar">
                <img className='logo' src={Logo} alt="Logo"/>
            </div>
            <div className="nav">
                {myNavItems.map((item, index) => (
                    <div
                        className={`navitem grid1x3 ${page === item.associatedPage ? 'selected' : ''}`}
                        onClick={() => handleNavClick(item.associatedPage)}
                        key={index}
                    >
                        <span>{item.text}</span>
                    </div>
                ))}
                {campaignsToShow.map((campaign: any, index: number) => (
                    <div
                        className={`
                            navitem
                            grid1x3
                            ${campaign === selectedCampaign && page === Pages.campaign ? 'selected' : ''
                        }`}
                        onClick={() => handleCampaignClick(campaign)}
                        key={index + 100}
                    >
                        <span>{getCampaignById(campaign, allCampaigns).name}</span>
                    </div>
                ))}
                <div className="navitem grid1x3" onClick={() => {window.location.href += 'help'}}>
                    <span>Help</span>
                </div>
                <Button handler={handleSignOut} text="Sign out" delay={500}/>
            </div>
            <div className={`main ${navOpened && isMobile() ? 'noclick' : ''}`}>
                <div className="maincontent" key={mainKey}>
                    {currentPage}
                </div>
            </div>
        </div>
    )
}

export default Home
