import React from 'react'
import './Nav.css'
import Logo from "../../Assets/logo.png";
import {Tabs, TabsEnum} from "./Tabs";
import Hamburger from '../../Assets/hamburger.svg'
import getText from "../../Utils/Translations";

function Nav(props: {
    chooseTab: (tab: TabsEnum) => void,
    toggleTablist: () => void
    currentTab: TabsEnum
}) {
    return (
        <div>
            <div className="large-nav">
                <img src={Logo} alt="Logo" className="logo" onClick={() => props.chooseTab(TabsEnum.home)}/>
                <div className="navactions grid1x3">
                    <div>
                        {Tabs.map(tab => (
                            <div
                                className={'tab' + (props.currentTab === tab.id ? ' selected-tab' : '')}
                                onClick={() => props.chooseTab(tab.id)}
                                key={tab.textID}
                                dangerouslySetInnerHTML={getText(tab.textID)}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className="small-nav">
                <div className="grid3x1">
                    <div className="hamburger a1 grid1x3">
                        <img src={Hamburger} alt="Hamburger" onClick={props.toggleTablist}/>
                    </div>
                    <img src={Logo} alt="Logo" className="a2 logo"/>
                </div>
            </div>
        </div>
    )
}

export default Nav
