import React, { useState } from 'react'
import Button from '../../Components/Button'
import DonationForm from './DonationForm'
import CallBackForm from './CallBackForm'
import SendMeLink from './SendMeLink'
import CampaignPicture from '../../Components/CampaignPicture'
import { cleanURL } from '../../Utils/PureFunctions'
import firebase from "firebase";

function Forms(props: {
    id: string,
    db: firebase.firestore.Firestore,
}) {
    const [formType, setFormType] = useState<'donation' | 'callback' | 'sendlink' | null>(null)

    if (formType === 'donation') return (
        <DonationForm id={props.id} db={props.db} back={() => setFormType(null)}/>
    )

    if (formType === 'callback') return (
        <CallBackForm id={props.id} db={props.db} back={() => setFormType(null)}/>
    )

    if (formType === 'sendlink') return (
        <SendMeLink id={props.id} db={props.db} back={() => setFormType(null)}/>
    )
    
    return (
        <div className="centered">
            <CampaignPicture id={props.id}/>
            <Button handler={() => setFormType('donation')} delay={500} text="Donation Form"/>
            <br/>
            <Button handler={() => setFormType('callback')} delay={500} text="Call Back Form"/>
            <br/>
            <Button handler={() => setFormType('sendlink')} delay={500} text="Send Me Link Form"/>
            <br/>
            <Button handler={cleanURL} delay={500} text="Back"/>
        </div>
    )
}

export default Forms
