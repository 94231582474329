import React, {useState, useEffect} from 'react'
import './Login.css'
import Logo from '../Assets/logo.png'
import Button from '../Components/Button'

import * as firebase from "firebase/app"
import ForgotPassword from './ForgotPassword'
import ErrorMessage from '../Components/ErrorMessage'
import InputField from '../Components/InputField'
import {goToLandingPage} from "../Utils/PureFunctions";

function Login() {
    const [email, setEmail] = useState('')
    const [opacity, setOpacity] = useState(0)
    const [password, setPassword] = useState('')
    const [buttonKey, setButtonKey] = useState(0)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [rememberPassword, setRememberPassword] = useState(true)
    const [forgotPasswordPage, setForgotPasswordPage] = useState(false)

    const resetButton = () => {
        setButtonKey(buttonKey + 1)
    }

    // set the opacity from 0 to 1 when the component loads for the first time (fade in animation)
    useEffect(() => {
        setOpacity(1)
    }, [])
    
    const handleSubmit = (event: React.FormEvent) => {
        const localPersistence = firebase.auth.Auth.Persistence.LOCAL
        const noPersistence = firebase.auth.Auth.Persistence.NONE
        event.preventDefault()
        firebase.auth().setPersistence(rememberPassword ? localPersistence : noPersistence).then(() => {
            // email and password states are updated by the inputs
            firebase.auth().signInWithEmailAndPassword(email, password).catch((error) => {
                // reset the sign in button to remove the spinner
                resetButton()
                setErrorMessage(error.message)
                console.log({error})
            })
        }).catch((error) => {
            // reset the sign in button to remove the spinner
            resetButton()
            setErrorMessage(error.message)
            console.log({error})
        })
    }

    const handleInvaidSubmit = () => {
        resetButton()
    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRememberPassword(event.target.checked)
    }

    const goToForgotPasswordPage = () => {
        setForgotPasswordPage(true)
    }

    // const demoLogin = () => {
    //     firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE).then(() => {
    //         // email and password states are updated by the inputs
    //         firebase.auth().signInWithEmailAndPassword('demo@account.com', 'password').catch((error) => {
    //             // reset the sign in button to remove the spinner
    //             resetButton()
    //             setErrorMessage(error.message)
    //             console.log({error})
    //         })
    //     })
    // }

    if (forgotPasswordPage) return (<ForgotPassword back={() => setForgotPasswordPage(false)}/>)

    return (
        <div className="login" style={{opacity}} data-testid="login">
            <div className="grid3x3">
                <img className="login-logo" src={Logo} alt="Logo" data-testid="login-logo"/>
            </div>
            <form onSubmit={handleSubmit} onInvalid={handleInvaidSubmit}>

                <InputField
                    type="email"
                    onChange={setEmail}
                    autoFocus={true}
                />

                <InputField
                    type="current-password"
                    onChange={setPassword}
                />

                <div className="checkboxgroup">
                    <span className="checkbox">
                        <input
                            className="checkbox"
                            type="checkbox"
                            name="remember-password"
                            id="remember-password"
                            onChange={handleCheckboxChange}
                            checked={rememberPassword}
                        />
                        <label htmlFor="remember-password" className="checkbox">Stay Logged In</label>
                    </span>
                </div>

                <ErrorMessage message={errorMessage}/>
            
                <div className="centered" data-testid="sign-in">
                    <Button text="Sign In" delay={-1} key={buttonKey}/>
                </div>
            </form>

            <div className="bigspace"/>

            <div className="centered">
                <div
                    className="forgot"
                    data-testid="forgot-password"
                    onClick={goToForgotPasswordPage}
                >Forgot password?</div>
            </div>

            <div className="centered">
                <div
                    className="forgot"
                    onClick={goToLandingPage}
                >Back to landing page</div>
            </div>
        </div>
    )
}

export default Login
