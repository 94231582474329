import React, { useState, useEffect } from 'react'
// import logo from './logo.svg'
import './App.css'
import './CSS/Style.css'
import './CSS/Grid3x3.css'
import './CSS/Grid1x3.css'
import './CSS/Grid3x1.css'
import Login from './Views/Login'
import Home from './Views/Home'
import RegisterUser from './Views/RegisterUser'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams
  } from "react-router-dom"

// set up firebase connectivity
import * as firebase from "firebase/app"
import ConnectionDetector from './Components/ConnectionDetector'
import UserGuide from './Views/UserGuide'
import Forms from './Views/Forms/Forms'
import LandingPage from './Views/LandingPage/LandingPage'

function App(props: {
    db: firebase.firestore.Firestore,
    showLanding: boolean
}) {
    const [currentUser, setUser] = useState<null | firebase.User>(null)
    const [homeKey, setHomeKey] = useState(0)
    const [showLanding, setShowLanding] = useState(props.showLanding)

    useEffect(() => {
        // when the login component signs the user in, this global firebase auth state is changed
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // Successful user sign in, save the details in the state
                setUser(user)
            } else {
                // User is signed out.
                setUser(null)
            }
        })
    }, [])

    const Child = () => {
        // We can use the `useParams` hook here to access
        // the dynamic pieces of the URL.
        let { page, id } = useParams()

        if (page === 'register' && id !== undefined) { return (
            <div className="app fullscreen" data-testid="app">
                <RegisterUser
                    token={id}
                    db={props.db}
                />
            </div>
        )}

        if (page === 'form' && id !== undefined) { return (
            <div className="app fullscreen grid3x3 autoscroll" data-testid="app">
                <Forms
                    id={id}
                    db={props.db}
                />
            </div>
        )}

        if (currentUser === null) {
            if (showLanding) return (
                <LandingPage goToLogin={() => setShowLanding(false)}/>
            )
            return (
                <div className="app fullscreen" data-testid="app">
                    <ConnectionDetector/>
                    <div className="grid3x3 fullscreen autoscroll">
                        <Login/>
                    </div>
                </div>
            )
        } else { return (
            <div className="app fullscreen" data-testid="app">
                <ConnectionDetector/>
                <div className="grid3x3 fullscreen autoscroll">
                    <Home
                        db={props.db}
                        reload={() => setHomeKey(homeKey + 1)}
                        key={homeKey}
                    />
                </div>
            </div>
        )}
    }

    return (
        <Router>
            <Switch>
                <Route path="/help">
                    <div className="app fullscreen" data-testid="app">
                        <ConnectionDetector/>
                        <div className="grid3x3 fullscreen autoscroll">
                            <UserGuide/>
                        </div>
                    </div>
                </Route>
                <Route path="/:page/:id">
                    <div className="app fullscreen" data-testid="app">
                        <ConnectionDetector/>
                        <div className="grid3x3 fullscreen autoscroll">
                            <Child />
                        </div>
                    </div>
                </Route>
                <Route path="/">
                    <Child />
                </Route>
            </Switch>
        </Router>
    )
}

export default App
