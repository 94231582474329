import React, { useState, useEffect } from 'react'
import * as firebase from "firebase/app"
import './MyAccount.css'
import Card from './Card'
import EditUser from './EditUser'
import ProfilePicture from '../Components/ProfilePicture'
import {upload} from "../Utils/PureFunctions";

function MyAccount(props: {
    userData: firebase.firestore.DocumentData,
    changePassword: () => void,
    myCampaigns: {
        id: string;
        name: string;
    }[],
    goToCampaign: (id: string) => void,
    db: firebase.firestore.Firestore,
    reload: () => void,
}) {
    const [showEdit, setShowEdit] = useState(false)
    const [userData, setUserData] = useState<null | firebase.firestore.DocumentData>(null)
    const [error, setError] = useState<string | null>(null)

    const email = firebase.auth().currentUser!.email!

    useEffect(() => {
        props.db.collection('users').doc(email).get().then(response => {
            setUserData(response)
        }).catch(error => {
            setError(error.message)
        })
    }, [props.db, email])

    const handleShowEdit = () => {
        setShowEdit(true)
    }

    const handleBack = () => {
        setShowEdit(false)
        props.reload()
    }

    const handleUploadImage = () => {
        if (userData === null) return
        upload('profiles', userData.id, () => {}, 500).then(() => {
            props.reload()
        }).catch(error => setError(error.message))
    }

    if (showEdit && userData !== null) return (
        <EditUser
            back={handleBack}
            db={props.db}
            user={userData}
            myUserData={userData}
        />
    )

    return (
        <Card
            title="My Account"
            buttons={[
                {text: "Change Password", delay: 500, handler: props.changePassword},
                {text: "Edit Details", delay: 500, handler: handleShowEdit}
            ]}
            error={error}
        >
            <ProfilePicture
                email={email}
                name={props.userData.name}
                onClick={handleUploadImage}
            />
            <table className="margin-auto">
                <tbody>
                    <tr>
                        <td><b>Name:</b></td>
                        <td>{props.userData.name}</td>
                    </tr>
                    <tr>
                        <td><b>Email:</b></td>
                        <td>{email}</td>
                    </tr>
                    <tr>
                        <td><b>Account type:</b></td>
                        <td className="capitalize">{props.userData.role}</td>
                    </tr>
                    <tr>
                        <td><b>My campaign(s):</b></td>
                        <td>{props.myCampaigns.map((campaign, index) => (
                            <span
                                className="blocklink"
                                onClick={() => props.goToCampaign(campaign.id)}
                                key={index}
                            >{campaign.name}</span>
                        ))}</td>
                    </tr>
                </tbody>
            </table>
        </Card>
    )
}

export default MyAccount
