import * as firebase from "firebase/app"
import "firebase/analytics"
import "firebase/auth"
import "firebase/firestore"
import 'firebase/functions'
export default () => {
    const firebaseConfig = {
        apiKey: "AIzaSyAJ2TVSdCJKT-5Ak90XmJbkUw4PLcun9nA",
        authDomain: "aspect-fs.firebaseapp.com",
        databaseURL: "https://aspect-fs.firebaseio.com",
        projectId: "aspect-fs",
        storageBucket: "aspect-fs.appspot.com",
        messagingSenderId: "841654045902",
        appId: "1:841654045902:web:25c308d3cd2f1a62c3949f",
        measurementId: "G-5M1WZR5K8Y"
    }
    firebase.initializeApp(firebaseConfig)
}
