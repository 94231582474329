import React, { useState, useEffect } from 'react'
import './Campaign.css'
import ErrorMessage from '../Components/ErrorMessage'
import {
    canCreateNewCampaign,
    canUploadRiskAssessment
} from '../Utils/Privileges'
import Spinner from '../Components/Spinner'
import EditCampaign from './EditCampaign'
import Card from './Card'
import assessmentColumns from '../Utils/AssessmentColumns'
import documentColumns from '../Utils/DocumentColumns'
import firebase from "firebase";
import {upload} from "../Utils/PureFunctions";
import EditAssessment from "./EditAssessment";
import CampaignPicture from "../Components/CampaignPicture";import Button from "../Components/Button";

function Campaign(props: {
    id: string | null,
    campaign: firebase.firestore.DocumentData,
    db: firebase.firestore.Firestore,
    reload: () => void,
    accountType: string,
    username: string
}) {
    const [error, setError] = useState<string | null>(null)
    const [showEdit, setShowEdit] = useState(false)
    const [assessments, setAssessments] = useState<firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[] | null>(null)
    const [loading, setLoading] = useState(false)
    const [showAssessment, setShowAssessment] = useState('')
    const [folder, setFolder] = useState<'assessments' | 'documents'>('assessments')
    const [columns, setColumns] = useState<typeof assessmentColumns>(assessmentColumns)

    useEffect(() => {
        let mounted = true
        if (!props.id) return
        props.db.collection(folder).where('campaign', '==', props.id).get().then(snapshot => {
            if (!mounted) return
            if (!snapshot.docs) setError('Snapshot documents is null.')
            setAssessments(snapshot.docs.sort((a, b) => a.data().uploadDate < b.data().uploadDate ? 1 : -1))
        }).catch(error => {
            if (!mounted) return
            setError(error.message)
            console.log({error})
        })
        return () => {mounted = false}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.db, props.id])

    useEffect(() => {
        pullData()
        if (folder === 'assessments') {
            setColumns(assessmentColumns)
        } else {
            setColumns(documentColumns)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [folder])

    if (props.id === null || assessments === null || loading) return (
        <div className="centered">
            <Spinner/>
            <ErrorMessage message={error}/>
        </div>
    )

    if (showEdit) return (
        <EditCampaign
            db={props.db}
            reload={props.reload}
            id={props.id}
            back={() => setShowEdit(false)}
        />
    )

    function pullData() {
        props.db.collection(folder).where('campaign', '==', props.id).get().then(snapshot => {
            if (!snapshot.docs) setError('Snapshot documents is null.')
            setAssessments(snapshot.docs.sort((a, b) => a.data().uploadDate < b.data().uploadDate ? 1 : -1))
        })
    }

    const handleAssessmentBack = () => {
        setShowAssessment('')
        pullData()
    }

    if (showAssessment) return (
        <EditAssessment
            db={props.db}
            reload={props.reload}
            id={showAssessment}
            folder={folder}
            back={handleAssessmentBack}
            accountType={props.accountType}
        />
    )

    const handleEdit = () => {
        setShowEdit(true)
    }

    const handleUploadAssessment = () => {
        const date = (new Date()).getTime()
        upload(folder, `${date}`, () => setLoading(true), 1000).then(data => {
            props.db.collection(folder).add({
                campaign: props.id,
                url: data.url,
                userEmail: firebase.auth().currentUser!.email!,
                username: props.username,
                uploadDate: date,
                filename: data.filename,
                notes: ''
            }).then((data) => {
                setShowAssessment(data.id)
            }).catch(error => {
                setError(error.message)
            }).finally(() => {
                setLoading(false)
            })
        }).catch(error => {
            setLoading(false)
            setError(error.message)
        })
    }

    const handleClickAssessment = (id: string) => {
        setShowAssessment(id)
    }

    const getUnhiddenAssessments = () => {
        const now = (new Date()).getTime()
        const msInADay = 1000 * 60 * 60 * 24
        if (folder === 'assessments') {
            return assessments.filter(ass => (now - ass.data().uploadDate) / msInADay < props.campaign.maxAssessmentAge)
        } else {
            return assessments
        }
    }

    const switchFolder = () => {
        if (folder === 'assessments') {
            setFolder('documents')
        } else {
            setFolder('assessments')
        }
    }

    return (
        <Card
            title={`${folder === 'assessments' ? 'Risk Assessments' : 'Documents'} for ${props.campaign.name} in the last ${props.campaign.maxAssessmentAge} days`}
            onClickTitle={handleEdit}
            error={error}
            description={props.campaign.description}
            buttons={[
                canUploadRiskAssessment(props.accountType) ? {
                    text: `Upload ${folder === 'assessments' ? 'Risk Assessment' : 'Document'}`,
                    delay: 500,
                    handler: handleUploadAssessment
                } : null,
                canCreateNewCampaign(props.accountType) ? {
                    text: "Edit Campaign Details",
                    delay: 500,
                    handler: handleEdit
                } : null,
            ]}
            class="campaign"
        >
            <CampaignPicture id={props.id} onClick={handleEdit}/>
            <p className="centered">
                <Button
                    handler={switchFolder}
                    delay={0}
                    text={`Go To ${folder === 'assessments' ? 'Documents' : 'Risk Assessments'}`}
                />
            </p>
            {getUnhiddenAssessments().length === 0 ? (
                <p className="centered">
                    No {
                        folder === 'assessments' ?
                            `assessments uploaded in the last ${props.campaign.maxAssessmentAge} days` :
                            'documents uploaded yet'
                    }
                </p>
            ) : (
                <table cellSpacing={0} className="margin-auto">
                    <thead>
                        <tr>
                            {columns.display.map((c, index) => (
                                <th className="nowrap" key={index}>{c.name}</th>
                            ))}

                        </tr>
                    </thead>
                    <tbody>
                        {getUnhiddenAssessments().map((d, index) => (
                            <tr key={index} onClick={() => handleClickAssessment(d.id)}>
                                {columns.display.map((c, index) => (
                                    <td className="nowrap" key={index}>{c.content(d)}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </Card>
    )
}

export default Campaign
