import React, { useState } from 'react'
import './ChangePassword.css'
import * as firebase from "firebase/app"
import InputField from '../Components/InputField'
import Card from './Card'

function ChangePassword(props: {
    goToMyAccount: () => void
}) {
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [error, setError] = useState<string | null>(null)

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        if (newPassword !== confirmPassword) {
            setError('You have entered two different passwords, please double check this and try again.')
            return
        }
        try {
            firebase.auth().currentUser?.updatePassword(newPassword).then(() => {
                props.goToMyAccount()
            }).catch((error) => {
                setError(error.message)
                console.log(error)
            })
        } catch (error) {
            setError('Something went wrong, try logging in again.')
            console.log(error)
        }
    }

    const handleInvaidSubmit = () => {
        setError('Some fields are invalid.')
    }

    return (
        <form onSubmit={handleSubmit} onInvalid={handleInvaidSubmit}>
            <Card
                title="Choose a new password"
                back={props.goToMyAccount}
                error={error}
                buttons={[
                    {text: "Change Password", delay: -1}
                ]}
            >
                <InputField
                    type="new-password"
                    onChange={setNewPassword}
                    autoFocus={true}
                />

                <InputField
                    type="confirm-password"
                    onChange={setConfirmPassword}
                />
            </Card>
        </form>
    )
}

export default ChangePassword
