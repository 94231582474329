import React, { useState } from 'react'
import './NewUser.css'
import * as firebase from "firebase/app"
import CampaignCheckboxes from '../Components/CampaignCheckboxes'
import RoleRadioButtons from '../Components/RoleRadioButtons'
import InputField from '../Components/InputField'
import { generateToken, registrationLink } from '../Utils/PureFunctions'
import { forceAllCampaignAccess } from '../Utils/Privileges'
import Card from './Card'

function NewUser(props: {
    db: firebase.firestore.Firestore,
    back: () => void
}) {
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [accountType, setAccountType] = useState<string | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [token, setToken] = useState<string | null>(null)
    const [campaigns, setCampaigns] = useState<string[]>([])

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault()

        const token = generateToken()

        await (new Promise(async (res, rej) => {
            if (campaigns.length === 0) {
                rej({message: 'A user must be associated with at least one campaign.'})
                return
            }
            
            let failure = false

            console.log('checking if user exists')
            await props.db.collection('users').doc(email).get().then(doc => {
                if (doc.exists) {
                    rej({message: 'This user already exists.'})
                    failure = true
                }
            }).catch(error => {rej(error); failure = true})

            if (failure) return

            console.log('saving user to db')
            await props.db.collection('users').doc(email).set({
                name: name,
                role: accountType,
                registrationToken: token,
                campaigns: campaigns
            }).catch(error => {rej(error); failure = true})

            if (failure) return
            
            res()

        })).then(() => {
            setToken(token)
        }).catch((error: any) => {
            setError(error.message)
            console.log({error})
        })

    }

    if (token !== null) return (
        <Card
            title={`Send the following link to ${email} to complete registration:`}
            class="newuser"
            buttons={[
                {text: "Ok", delay: 500, handler: props.back}
            ]}
        >
            <span className="selectable link">
                {registrationLink(token)}
            </span>
        </Card>
    )

    return (
        <form onSubmit={handleSubmit}>
            <Card
                title="New User"
                back={props.back}
                error={error}
                class="newuser"
                buttons={[
                    {text: "Create Link", delay: -1}
                ]}
            >
                <InputField
                    type="custom"
                    customLabel="Name"
                    onChange={setName}
                    autoFocus={true}
                    disableAutoComplete={true}
                />

                <InputField
                    type="email"
                    onChange={setEmail}
                    disableAutoComplete={true}
                />

                <RoleRadioButtons
                    setAccountType={setAccountType}
                    accountType={accountType}
                />

                <CampaignCheckboxes
                    db={props.db}
                    onError={setError}
                    updateSelectedCampaigns={setCampaigns}
                    initial={campaigns}
                    selectAll={forceAllCampaignAccess(accountType)}
                />
            </Card>
        </form>
    )
}

export default NewUser
