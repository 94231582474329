import firebase from "firebase";
import {getDateFormat} from "./PureFunctions";

export default {
    display: [
        {
            name: 'Filename',
            content: (doc: firebase.firestore.DocumentData) => {
                const data = doc.data()
                return `${data.filename}`
            }
        },
        {
            name: 'Uploaded by',
            content: (doc: firebase.firestore.DocumentData) => {
                const data = doc.data()
                return `${data.username}`
            }
        },
        {
            name: 'Date',
            content: (doc: firebase.firestore.DocumentData) => {
                const data = doc.data()
                return getDateFormat(data.uploadDate)
            }
        },
        {
            name: 'Notes',
            content: (doc: firebase.firestore.DocumentData) => {
                const data = doc.data()
                return `${data.notes}`
            }
        },
    ],
    export: []
}