import React from 'react'
import './Spinner.css'

function Spinner() {
    return (
        <div data-testid="spinner" className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    )
}

export default Spinner
