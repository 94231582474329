import React, {useState} from 'react'
import './LandingPage.css'
import Nav from './Nav'
import {TabsEnum} from "./Tabs";
import Old from './Old'
import Home from "./Home";
import About from "./About";
import Covid19 from "./Covid19";
import Services from "./Services";
import Promotion from "./Promotion";
import Security from "./Security";
import TabList from "./TabList";
import Contact from "./Contact";
import Complaints from './Complaints';

function LandingPage(props: {
    goToLogin: () => void,
}) {
    const [selectedTab, selectTab] = useState(TabsEnum.home)
    const [tablistOpen, setTablistOpen] = useState(false)

    const toggleTablist = () => {
        setTablistOpen(!tablistOpen)
    }

    const chooseTab = (tab: TabsEnum) => {
        document.documentElement.scrollTop = 0
        if (tab === TabsEnum.login) {
            props.goToLogin()
            return
        }
        selectTab(tab)
        if (tablistOpen) {
            setTablistOpen(false)
        }
    }

    return (
        <div className="landingpage" style={{justifyContent: tablistOpen ? '' : 'space-between'}}>
            <Nav
                chooseTab={chooseTab}
                toggleTablist={toggleTablist}
                currentTab={selectedTab}
            />
            <div className="body">
                {(() => {
                    if (tablistOpen) {
                        return <TabList chooseTab={chooseTab}/>
                    } else if (selectedTab === TabsEnum.home) {
                        return <Home chooseTab={chooseTab}/>
                    } else if (selectedTab === TabsEnum.about) {
                        return <About chooseTab={chooseTab}/>
                    } else if (selectedTab === TabsEnum.covid19) {
                        return <Covid19 chooseTab={chooseTab}/>
                    } else if (selectedTab === TabsEnum.services) {
                        return <Services chooseTab={chooseTab}/>
                    } else if (selectedTab === TabsEnum.promotion) {
                        return <Promotion chooseTab={chooseTab}/>
                    } else if (selectedTab === TabsEnum.security) {
                        return <Security chooseTab={chooseTab}/>
                    } else if (selectedTab === TabsEnum.contact) {
                        return <Contact chooseTab={chooseTab}/>
                    } else if (selectedTab === TabsEnum.complaints) {
                        return <Complaints chooseTab={chooseTab}/>
                    } else {
                        return <Old/>
                    }
                })()}
            </div>
        </div>
    )
}

export default LandingPage
