import React, { useState, useEffect } from 'react'
import './CampaignPicture.css'
import { download } from '../Utils/PureFunctions'
import Spinner from './Spinner'

function CampaignPicture(props: {
    id: string,
    onClick?: () => void
}) {
    const [picture, setPicture] = useState<string | null>(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let mounted = true
        download('campaigns', props.id).then(url => {
            if (!mounted) return
            setPicture(url)
            setLoading(false)
        }).catch(error => {
            if (!mounted) return
            console.log(error.message)
            setLoading(false)
        })
        return () => {mounted = false}
    }, [props.id])

    if (loading) return (
        <div className="grid3x3">
            <Spinner/>
        </div>
    )

    return picture ? (
        <div className="grid3x3">
            <img
                className="campaign-picture"
                src={picture}
                alt=""
                onClick={props.onClick}
                style={{cursor: props.onClick ? 'pointer' : 'auto'}}
            />
        </div>
    ) : (
        <div className="grid3x3">
            <p>No image uploaded</p>
        </div>
    )
}

export default CampaignPicture
