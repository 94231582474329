import React from 'react'
import './TitleWithBack.css'
import Back from '../Assets/back.svg'

function TitleWithBack(props: {
    text: string,
    onClick: () => void
}) {
    return (
        <div className="title-with-back" onClick={props.onClick}>
            <img src={Back} alt="Back"/>
            <h1>{props.text}</h1>
        </div>
    )
}

export default TitleWithBack
